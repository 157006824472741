/**
 * @generated SignedSource<<ffa9e63574ac02cba077479f32e9fa64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieInReviewData$data = {
  readonly id: string;
  readonly movieClips: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationItemClip">;
  }>;
  readonly reviewer: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"MovieInReviewApproveData" | "MovieInReviewRemandData" | "SwingPositionClassificationChat" | "SwingPositionClassificationItemMeta" | "SwingPositionClassificationSummary">;
  readonly " $fragmentType": "MovieInReviewData";
};
export type MovieInReviewData$key = {
  readonly " $data"?: MovieInReviewData$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieInReviewData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieInReviewData",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "reviewer",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationSummary"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationChat"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationItemMeta"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieInReviewRemandData"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieInReviewApproveData"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieClip",
      "kind": "LinkedField",
      "name": "movieClips",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SwingPositionClassificationItemClip"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};
})();

(node as any).hash = "0db828d51dd52f877cbc7509d655f658";

export default node;
