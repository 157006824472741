/**
 * @generated SignedSource<<4848d0a3fd633b58144b1836dab40922>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieJobWorkingQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobItemQuery" | "SwingPositionClassificationMetaQuery">;
  readonly " $fragmentType": "MovieJobWorkingQuery";
};
export type MovieJobWorkingQuery$key = {
  readonly " $data"?: MovieJobWorkingQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobWorkingQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieJobWorkingQuery",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieJobItemQuery"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationMetaQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ad95fdd794cf00aabc0264103e65b38a";

export default node;
