import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import MovieInReview from "@components/templates/MovieInReview";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { MovieInReviewScreenQuery } from "@generated/MovieInReviewScreenQuery.graphql";
import useQueryId from "@lib/hooks/useQueryId";

const query = graphql`
  query MovieInReviewScreenQuery($id: ID!) {
    movieMetainfo(id: $id) {
      ...MovieInReviewData
    }
    admin {
      ...MovieInReviewAdmin
    }
    ...MovieInReviewQuery
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<MovieInReviewScreenQuery>;
}): JSX.Element {
  const data = usePreloadedQuery<MovieInReviewScreenQuery>(
    query,
    queryReference
  );
  const { movieMetainfo, admin } = data;
  return (
    <MovieInReview
      adminFragment={admin}
      masterFragment={data}
      movieMetainfoFragment={movieMetainfo}
    />
  );
}

export default function MovieInReviewScreen(): JSX.Element {
  const id = useQueryId();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<MovieInReviewScreenQuery>(query);
  useEffect(() => {
    loadQuery({ id });
    return () => {
      disposeQuery();
    };
  }, [id, loadQuery, disposeQuery]);

  return (
    <ContentLayout
      breadcrumbs={[
        { label: "スイング動画レビュー一覧", path: "/movie_in_reviews" },
      ]}
      title="スイング動画レビュー詳細"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
