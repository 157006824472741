/**
 * @generated SignedSource<<849e689ec403e465dbdfed28048cbf7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieJobStatus = "assigned" | "closing" | "done" | "generating" | "ignored" | "in_working" | "waiting";
export type MovieMetainfoAngle = "back" | "front";
export type MovieMetainfoDirection = "landscape" | "portrait" | "square";
export type MovieMetainfoGender = "female" | "male" | "unknown";
export type MovieMetainfoPlace = "course" | "driving_range" | "indoor";
export type MovieMetainfoSwing = "full" | "half" | "quarter";
import { FragmentRefs } from "relay-runtime";
export type MovieJobWorking$data = {
  readonly id: string;
  readonly movieMetainfo: {
    readonly angle: MovieMetainfoAngle | null | undefined;
    readonly club: {
      readonly name: string;
    } | null | undefined;
    readonly direction: MovieMetainfoDirection | null | undefined;
    readonly gender: MovieMetainfoGender | null | undefined;
    readonly id: string;
    readonly place: MovieMetainfoPlace | null | undefined;
    readonly swing: MovieMetainfoSwing | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationMeta" | "useSwingFrameUrl">;
  };
  readonly status: MovieJobStatus;
  readonly tmpImages: ReadonlyArray<{
    readonly id: string;
    readonly movieClip: {
      readonly id: string;
    } | null | undefined;
    readonly signedUrl: string;
    readonly " $fragmentSpreads": FragmentRefs<"MovieJobItem">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobGenerate" | "MovieJobIgnore" | "MovieJobItemData">;
  readonly " $fragmentType": "MovieJobWorking";
};
export type MovieJobWorking$key = {
  readonly " $data"?: MovieJobWorking$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobWorking">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieJobWorking",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieMetainfo",
      "kind": "LinkedField",
      "name": "movieMetainfo",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Club",
          "kind": "LinkedField",
          "name": "club",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "angle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "place",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "direction",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gender",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "swing",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SwingPositionClassificationMeta"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useSwingFrameUrl"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TmpImage",
      "kind": "LinkedField",
      "name": "tmpImages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signedUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MovieClip",
          "kind": "LinkedField",
          "name": "movieClip",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MovieJobItem"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieJobGenerate"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieJobItemData"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieJobIgnore"
    }
  ],
  "type": "MovieJob",
  "abstractKey": null
};
})();

(node as any).hash = "e288049f04f3a962bd7a258993c1c823";

export default node;
