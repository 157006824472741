import { useState, useCallback } from "react";
import {
  TableRow,
  TableCell,
  Stack,
  Dialog,
  DialogContent,
  IconButton,
  Button,
} from "@mui/material";
import { object, string, mixed } from "@lib/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Form,
  TextField,
  SelectField,
  ErrorText,
} from "@components/molecules/TextInput";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { MovieJobsIgnoredItem$key } from "@generated/MovieJobsIgnoredItem.graphql";
import { MovieJobsIgnoredItemSwingQuery$key } from "@generated/MovieJobsIgnoredItemSwingQuery.graphql";
import {
  MovieJobsIgnoredItemMutation,
  MovieJobsIgnoredItemMutation$data,
  MovieJobStatus,
} from "@generated/MovieJobsIgnoredItemMutation.graphql";
import Loading from "@components/atoms/Loading";
import { decodeId } from "@lib/utils/convertId";
import { getKeys, isNil, truncate } from "@lib/utils/commons";
import SwingPositionClassificationMeta from "@components/molecules/SwingPositionClassification/SwingPositionClassificationMeta";
import Icon from "@components/atoms/Icon";
import Spacer from "@components/atoms/Spacer";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import { MovieMetainfoSwing, MovieJobStatus as statuses } from "@constants/App";

const query = graphql`
  fragment MovieJobsIgnoredItem on MovieJob {
    id
    ignoreReason
    status
    movieMetainfo {
      id
      user {
        name
      }
      club {
        name
      }
      swing
      ...SwingPositionClassificationMeta
    }
  }
`;

const swingQuery = graphql`
  fragment MovieJobsIgnoredItemSwingQuery on Query {
    ...SwingPositionClassificationMetaQuery
  }
`;

const mutation = graphql`
  mutation MovieJobsIgnoredItemMutation($input: UpdateMovieJobMutationInput!) {
    updateMovieJob(input: $input) {
      __typename
      ... on MovieJob {
        id
        ignoreReason
        status
        movieMetainfo {
          id
          user {
            id
            name
          }
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

type Input = {
  ignoreReason: string;
  status: MovieJobStatus;
};

const SelectableStatuses = ["assigned", "ignored"] as const;
const StatusOptions = SelectableStatuses.map((key) => ({
  label: statuses[key],
  value: key,
}));

export default function MovieJobsIgnoredItem({
  movieJobFragment,
  swingsFragment,
}: {
  movieJobFragment: MovieJobsIgnoredItem$key;
  swingsFragment: MovieJobsIgnoredItemSwingQuery$key;
}) {
  const [, setMessage] = useMessage();
  const movieJob = useFragment(query, movieJobFragment);
  const { id, ignoreReason, status, movieMetainfo } = movieJob;
  const data = useFragment(swingQuery, swingsFragment);
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [commit] = useMutation<MovieJobsIgnoredItemMutation>(mutation);
  const [target, setTarget] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<Input>({
    defaultValues: {
      ignoreReason: ignoreReason ?? "",
      status,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        ignoreReason: string()
          .default("")
          .test("compare", "除外理由を入力してください", (value) => {
            if (getValues("status") === "ignored" && value === "") {
              return false;
            }
            return true;
          }),
        status: mixed<MovieJobStatus>()
          .oneOf(getKeys(statuses), "選択してください")
          .required("選択してくださいしてください"),
      })
    ),
  });
  const handleSave = useCallback(async () => {
    await handleSubmit(
      async ({ ignoreReason: inputIgnoreReason, status: inputStatus }) => {
        if (processing) {
          return;
        }
        onClick();
        setConfirm(false);
        const result = await new Promise<
          MovieJobsIgnoredItemMutation$data["updateMovieJob"]
        >((resolve) => {
          commit({
            variables: {
              input: {
                id,
                ignoreReason: inputIgnoreReason,
                status: inputStatus,
              },
            },
            onCompleted: ({ updateMovieJob }) => {
              resolve(updateMovieJob);
            },
          });
        });
        if (result.__typename !== "MovieJob") {
          setMessage({
            mode: "error",
            title:
              result.__typename === "UserError"
                ? result.message
                : "保存できませんでした",
          });
          onRelease();
        } else {
          window.location.reload();
        }
      }
    )();
  }, [commit, id, handleSubmit, setMessage, processing, onClick, onRelease]);
  return (
    <>
      <TableRow>
        <TableCell>{decodeId(movieJob.id)}</TableCell>
        <TableCell>{movieJob.movieMetainfo.user?.name}</TableCell>
        <TableCell>
          {!isNil(movieJob.movieMetainfo.swing)
            ? MovieMetainfoSwing[movieJob.movieMetainfo.swing]
            : ""}
        </TableCell>
        <TableCell>{movieJob.movieMetainfo.club?.name}</TableCell>
        <TableCell>{truncate(ignoreReason, { length: 20 })}</TableCell>
        <TableCell>
          <Button onClick={() => setTarget(true)}>詳細</Button>
        </TableCell>
        <TableCell>
          <Button onClick={() => setConfirm(true)}>編集</Button>
        </TableCell>
      </TableRow>
      {target && (
        <Dialog fullWidth onClose={() => setTarget(false)} open scroll="paper">
          <Stack direction="row" justifyContent="flex-end">
            <IconButton aria-label="close" onClick={() => setTarget(false)}>
              <Icon name="close" size={24} />
            </IconButton>
          </Stack>
          <SwingPositionClassificationMeta
            clubsFragment={data}
            movieInfoFragment={movieMetainfo}
          />
        </Dialog>
      )}
      <Dialog fullWidth onClose={() => setConfirm(false)} open={confirm}>
        <Stack direction="row" justifyContent="flex-end">
          <IconButton onClick={() => setConfirm(false)}>
            <Icon name="close" size={24} />
          </IconButton>
          <Spacer width={8} />
        </Stack>
        <DialogContent>
          <Form>
            <Stack direction="column" spacing={2}>
              <SelectField
                control={control}
                label="ステータス"
                name="status"
                options={StatusOptions}
              />
              {!isValid && errors.status !== undefined && (
                <ErrorText error={errors.status.message} />
              )}
              <Spacer height={24} />
              <TextField
                control={control}
                label="除外理由"
                name="ignoreReason"
              />
              {!isValid && errors.ignoreReason !== undefined && (
                <ErrorText error={errors.ignoreReason.message} />
              )}
              <Spacer height={24} />
              <Stack alignItems="flex-end" direction="row">
                <Button
                  disabled={!isValid}
                  onClick={handleSave}
                  variant="contained"
                >
                  保存する
                </Button>
              </Stack>
              <Spacer height={18} />
            </Stack>
          </Form>
        </DialogContent>
      </Dialog>
      {processing && <Loading mask />}
    </>
  );
}
