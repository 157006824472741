/**
 * @generated SignedSource<<25da8f250771d8c352925c7db1e15226>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoDetection$data = {
  readonly id: string;
  readonly movieClips: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoDetectionItem">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoDetectionItemDirection">;
  readonly " $fragmentType": "MovieMetainfoDetection";
};
export type MovieMetainfoDetection$key = {
  readonly " $data"?: MovieMetainfoDetection$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoDetection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfoDetection",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieMetainfoDetectionItemDirection"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieClip",
      "kind": "LinkedField",
      "name": "movieClips",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MovieMetainfoDetectionItem"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};
})();

(node as any).hash = "41ca14ac16bfcea67a687527680878f7";

export default node;
