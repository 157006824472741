/**
 * @generated SignedSource<<c06ac64108d52cd37108e5bc47fa896c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CommentWorkType = "classification" | "object_detection";
export type CreateCommentMutationInput = {
  clientMutationId?: string | null | undefined;
  content: string;
  movieClipId?: string | null | undefined;
  movieMetainfoId: string;
  workType: CommentWorkType;
};
export type SwingPositionClassificationChatMutation$variables = {
  input: CreateCommentMutationInput;
};
export type SwingPositionClassificationChatMutation$data = {
  readonly createComment: {
    readonly __typename: "CommentItem";
    readonly comment: {
      readonly id: string;
    };
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type SwingPositionClassificationChatMutation = {
  response: SwingPositionClassificationChatMutation$data;
  variables: SwingPositionClassificationChatMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "createComment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Comment",
            "kind": "LinkedField",
            "name": "comment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "CommentItem",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UserError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwingPositionClassificationChatMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwingPositionClassificationChatMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2eecafd333e2d4d0b07be8e3afd6f026",
    "id": null,
    "metadata": {},
    "name": "SwingPositionClassificationChatMutation",
    "operationKind": "mutation",
    "text": "mutation SwingPositionClassificationChatMutation(\n  $input: CreateCommentMutationInput!\n) {\n  createComment(input: $input) {\n    __typename\n    ... on CommentItem {\n      comment {\n        id\n      }\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2319ee24de1b853293d3ac42099ac606";

export default node;
