import React, { useCallback, useMemo, useState, useRef } from "react";
import {
  Stack,
  Card,
  List,
  ListItem,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import Text from "@components/atoms/Text";
import Loading from "@components/atoms/Loading";
import Content from "@components/atoms/Content";
import Message from "@components/molecules/Message";
import Icon from "@components/atoms/Icon";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import useScroll from "@lib/hooks/useScroll";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import SwingPositionClassificationMeta from "@components/molecules/SwingPositionClassification/SwingPositionClassificationMeta";
import MovieJobItem from "@components/organisms/MovieJob/MovieJobItem";
import MovieJobGenerate from "@components/organisms/MovieJob/MovieJobGenerate";
import MovieJobIgnore from "@components/organisms/MovieJob/MovieJobIgnore";
import Spacer from "@components/atoms/Spacer";
import useMessage from "@lib/hooks/useMessage";
import { MovieJobWorking$key } from "@generated/MovieJobWorking.graphql";
import { MovieJobWorkingQuery$key } from "@generated/MovieJobWorkingQuery.graphql";
import {
  MovieJobWorkingMutation,
  MovieJobWorkingMutation$data,
} from "@generated/MovieJobWorkingMutation.graphql";
import { isNil } from "@lib/utils/commons";
import Colors from "@constants/Colors";
import { Working } from "@components/organisms/MovieJob/hints";
import useSwingFrameUrl from "@lib/hooks/useSwingFrameUrl";
import { MovieMetainfoAngle, MovieMetainfoSwing } from "@constants/App";

const query = graphql`
  fragment MovieJobWorking on MovieJob {
    id
    status
    movieMetainfo {
      id
      club {
        name
      }
      angle
      place
      direction
      gender
      swing
      ...SwingPositionClassificationMeta
      ...useSwingFrameUrl
    }
    tmpImages {
      id
      signedUrl
      movieClip {
        id
      }
      ...MovieJobItem
    }
    ...MovieJobGenerate
    ...MovieJobItemData
    ...MovieJobIgnore
  }
`;

const masterQuery = graphql`
  fragment MovieJobWorkingQuery on Query {
    ...MovieJobItemQuery
    ...SwingPositionClassificationMetaQuery
  }
`;

const mutation = graphql`
  mutation MovieJobWorkingMutation($input: RemoveTmpImagesMutationInput!) {
    removeTmpImages(input: $input) {
      id
      movieMetainfo {
        id
        angle
        movieClips {
          id
          swingImage {
            id
            signedUrl
          }
        }
      }
      tmpImages {
        id
        signedUrl
      }
    }
  }
`;

export default function MovieJobWorking({
  movieJobFragment,
  masterFragment,
}: {
  movieJobFragment: MovieJobWorking$key;
  masterFragment: MovieJobWorkingQuery$key;
}) {
  const ref = useRef<HTMLUListElement | null>(null);
  const { scrollTop, scrollBottom } = useScroll();
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [, setMessage] = useMessage();
  const movieJob = useFragment<MovieJobWorking$key>(query, movieJobFragment);
  const { id, status, movieMetainfo, tmpImages } = movieJob;
  const master = useFragment<MovieJobWorkingQuery$key>(
    masterQuery,
    masterFragment
  );
  const swingFrameUrl = useSwingFrameUrl({
    movieMetainfoFragment: movieMetainfo,
  });
  const generatable = useMemo<boolean>(() => {
    const { club, place, direction, gender, swing, angle } = movieMetainfo;
    if (
      isNil(club) ||
      isNil(angle) ||
      isNil(place) ||
      isNil(direction) ||
      isNil(gender) ||
      isNil(swing)
    ) {
      return false;
    }
    return true;
  }, [movieMetainfo]);
  const metaDisplay = useMemo<string>(() => {
    const displays: string[] = [];
    const { club, swing, angle } = movieMetainfo;
    if (!isNil(club)) {
      displays.push(club.name);
    }
    if (!isNil(swing)) {
      displays.push(MovieMetainfoSwing[swing]);
    }
    if (!isNil(angle)) {
      displays.push(MovieMetainfoAngle[angle]);
    }
    return displays.join("/");
  }, [movieMetainfo]);
  const [dialog, setDialog] = useState<boolean>(false);
  const [selectedRemoves, setSelectedRemoves] = useState<string[]>([]);
  const [removeCommit] = useMutation<MovieJobWorkingMutation>(mutation);
  const boxHeight = useMemo<number>(() => {
    if (!isNil(movieMetainfo.direction)) {
      return movieMetainfo.direction === "portrait" ? 400 : 300;
    }
    return 300;
  }, [movieMetainfo]);

  const scrollTo = useCallback(
    (mode: "top" | "bottom") => {
      if (ref.current === null) {
        return;
      }
      if (mode === "top") {
        scrollTop(ref);
      } else {
        scrollBottom(ref);
      }
    },
    [scrollTop, scrollBottom]
  );
  const handleRemoveItem = useCallback(
    (filename: string) => {
      const removeImages = selectedRemoves.filter((item) => item !== filename);
      if (removeImages.length === selectedRemoves.length) {
        setSelectedRemoves([...selectedRemoves, filename]);
      } else {
        setSelectedRemoves(removeImages);
      }
    },
    [selectedRemoves]
  );

  const handleRemoveItems = useCallback(
    (imageId: string, mode: "before" | "after") => {
      const targets =
        mode === "before"
          ? tmpImages.slice(
              0,
              tmpImages.findIndex((item) => item.id === imageId) + 1
            )
          : tmpImages.slice(tmpImages.findIndex((item) => item.id === imageId));
      setSelectedRemoves(
        targets.filter((item) => item.movieClip === null).map((item) => item.id)
      );
    },
    [tmpImages]
  );

  const handleRemove = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    await new Promise<MovieJobWorkingMutation$data["removeTmpImages"]>(
      (resolve) => {
        removeCommit({
          variables: {
            input: {
              ids: selectedRemoves,
              movieJobId: id,
            },
          },
          onCompleted: ({ removeTmpImages }) => {
            resolve(removeTmpImages);
          },
        });
      }
    );
    setMessage({
      mode: "toast",
      title: "削除しました",
    });
    onRelease();
    setSelectedRemoves([]);
    setDialog(false);
  }, [
    id,
    removeCommit,
    processing,
    selectedRemoves,
    onClick,
    onRelease,
    setMessage,
  ]);

  return (
    <>
      <Stack direction="column" spacing={2}>
        <SwingPositionClassificationMeta
          clubsFragment={master}
          movieInfoFragment={movieMetainfo}
        />
        {status === "assigned" && (
          <>
            <Divider />
            <MovieJobIgnore movieJobFragment={movieJob} />
          </>
        )}
      </Stack>
      {generatable && (
        <>
          <Spacer height={24} />
          <Divider />

          <Spacer height={24} />

          <Card sx={{ padding: 2, backgroundColor: Colors.blue20 }}>
            <Text bold variant="h5">
              ラベル付け手順
            </Text>
            <Spacer height={8} />
            {Working.map((hint) => (
              <Content
                key={hint.title}
                column={hint.title}
                columnWidth={50}
                value={hint.value}
              />
            ))}
          </Card>
          {swingFrameUrl !== null && (
            <Stack direction="row" justifyContent="flex-end">
              <Button
                onClick={() => {
                  window.open(
                    swingFrameUrl.url,
                    "_blank",
                    "width=600,height=600"
                  );
                }}
              >
                スイングポジション定義書を見る ({swingFrameUrl.label})
              </Button>
            </Stack>
          )}
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Text>{metaDisplay}</Text>
            <Text bold>{tmpImages.length}枚</Text>
          </Stack>
          <Spacer height={8} />
          <Divider />
          <List ref={ref}>
            {tmpImages.map((tmpImage) => (
              <React.Fragment key={tmpImage.id}>
                <ListItem
                  sx={{
                    backgroundColor: selectedRemoves.includes(tmpImage.id)
                      ? Colors.orange20
                      : "transparent",
                  }}
                >
                  <MovieJobItem
                    boxHeight={boxHeight}
                    editable={status === "in_working"}
                    imageFragment={tmpImage}
                    jobFragment={movieJob}
                    onSelectRemove={handleRemoveItem}
                    onSelectRemoves={handleRemoveItems}
                    selectedRemove={selectedRemoves.includes(tmpImage.id)}
                    swingPositionFragment={master}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          <MovieJobGenerate movieJobFragment={movieJob} />
          <Stack
            direction="row"
            spacing={1}
            sx={{
              position: "fixed",
              bottom: 0,
              right: 0,
              padding: 2,
            }}
          >
            <IconButton onClick={() => scrollTop()}>
              <Icon name="up" size={24} />
            </IconButton>
            <IconButton onClick={() => scrollTo("bottom")}>
              <Icon name="down" size={24} />
            </IconButton>
            <Button
              disabled={selectedRemoves.length === 0}
              onClick={() => setDialog(true)}
              variant="contained"
            >
              選択した画像を削除する({selectedRemoves.length}件)
            </Button>
          </Stack>
        </>
      )}
      <Message
        closeText="キャンセル"
        confirmText="理解して、削除する"
        message="選択した画像をまとめて削除します。この処理は取り消せません。"
        onClose={() => setDialog(false)}
        onConfirm={handleRemove}
        title="まとめて削除しますか？"
        visible={dialog}
      />
      {processing && <Loading color={Colors.white} mask />}
    </>
  );
}
