/**
 * @generated SignedSource<<9623f82acc54699b0df0fc5d62e01d97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ApproveLabelingMutationInput = {
  clientMutationId?: string | null | undefined;
  movieMetainfoId: string;
};
export type MovieInReviewApproveMutation$variables = {
  input: ApproveLabelingMutationInput;
};
export type MovieInReviewApproveMutation$data = {
  readonly approveLabeling: {
    readonly message: string | null | undefined;
    readonly success: boolean;
  };
};
export type MovieInReviewApproveMutation = {
  response: MovieInReviewApproveMutation$data;
  variables: MovieInReviewApproveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProcessResult",
    "kind": "LinkedField",
    "name": "approveLabeling",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MovieInReviewApproveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MovieInReviewApproveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "68d996147f08fca4e8fe549d8315db07",
    "id": null,
    "metadata": {},
    "name": "MovieInReviewApproveMutation",
    "operationKind": "mutation",
    "text": "mutation MovieInReviewApproveMutation(\n  $input: ApproveLabelingMutationInput!\n) {\n  approveLabeling(input: $input) {\n    success\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "e404194949f53d37e5c2c91aebb6828e";

export default node;
