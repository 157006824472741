/**
 * @generated SignedSource<<c53d73efbc5a5bfe2c3e4860887fa314>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommentWorkType = "classification" | "object_detection";
import { FragmentRefs } from "relay-runtime";
export type CommentsItem$data = {
  readonly commentUsers: ReadonlyArray<{
    readonly id: string;
    readonly lastReadAt: any | null | undefined;
    readonly unreadCount: number;
    readonly user: {
      readonly avatar: {
        readonly signedUrl: string | null | undefined;
      } | null | undefined;
      readonly id: string;
      readonly name: string;
    };
  }>;
  readonly contentCount: number;
  readonly id: string;
  readonly lastPostedAt: any;
  readonly movieMetainfo: {
    readonly thumbnail: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"useMovieMetainfo">;
  };
  readonly user: {
    readonly avatar: {
      readonly signedUrl: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly name: string;
  };
  readonly workType: CommentWorkType;
  readonly " $fragmentType": "CommentsItem";
};
export type CommentsItem$key = {
  readonly " $data"?: CommentsItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentsItem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Avatar",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signedUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentsItem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastPostedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieMetainfo",
      "kind": "LinkedField",
      "name": "movieMetainfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useMovieMetainfo"
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentUser",
      "kind": "LinkedField",
      "name": "commentUsers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastReadAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadCount",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
})();

(node as any).hash = "7fe575a528ae79b065e80de925e7850c";

export default node;
