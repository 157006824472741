import { useEffect, Suspense } from "react";
import { Tab, Tabs } from "@mui/material";
import ContentLayout from "@navigation/ContentLayout";
import MovieJob, { TabType } from "@components/templates/MovieJob";
import { useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { MovieJobScreenQuery } from "@generated/MovieJobScreenQuery.graphql";
import useQueryId from "@lib/hooks/useQueryId";
import Spacer from "@components/atoms/Spacer";

const query = graphql`
  query MovieJobScreenQuery($id: ID!) {
    movieJob(id: $id) {
      ...MovieJob
    }
    admin {
      ...MovieJobAdmin
    }
    ...MovieJobQuery
  }
`;

function ScreenContent({
  queryReference,
  tab,
}: {
  queryReference: PreloadedQuery<MovieJobScreenQuery>;
  tab: TabType;
}) {
  const data = usePreloadedQuery<MovieJobScreenQuery>(query, queryReference);
  return (
    <MovieJob
      adminFragment={data.admin}
      movieJobFragment={data.movieJob}
      swingsFragment={data}
      tab={tab}
    />
  );
}

export default function MovieJobScreen({ tab }: { tab: TabType }) {
  const id = useQueryId();
  const navigate = useNavigate();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<MovieJobScreenQuery>(query);
  useEffect(() => {
    loadQuery(
      { id },
      {
        fetchPolicy: "network-only",
      }
    );
    return () => {
      disposeQuery();
    };
  }, [id, loadQuery, disposeQuery]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: "working" | "confirm"
  ) => {
    navigate(`/movie_jobs/${id}/${newValue === "confirm" ? "confirm" : ""}`);
    event.preventDefault();
  };

  return (
    <ContentLayout
      breadcrumbs={[{ label: "作業動画一覧", path: "/movie_jobs" }]}
      title="作業動画"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <>
            <Tabs onChange={handleChange} value={tab}>
              <Tab label="作業中" value="working" />
              <Tab label="確認中" value="confirm" />
            </Tabs>
            <Spacer height={16} />
            <ScreenContent queryReference={queryReference} tab={tab} />
          </>
        )}
      </Suspense>
    </ContentLayout>
  );
}
