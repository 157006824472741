/**
 * @generated SignedSource<<d4cf0712695236e8f9f9badf0e80912d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieJobConfirmAdmin$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationChatAdmin">;
  readonly " $fragmentType": "MovieJobConfirmAdmin";
};
export type MovieJobConfirmAdmin$key = {
  readonly " $data"?: MovieJobConfirmAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobConfirmAdmin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieJobConfirmAdmin",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationChatAdmin"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0a632898a36860266a7ca0c91bb8a27a";

export default node;
