import { useCallback, useState } from "react";
import { Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import useMessage from "@lib/hooks/useMessage";
import Message from "@components/molecules/Message";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { MovieInReviewApproveData$key } from "@generated/MovieInReviewApproveData.graphql";
import {
  MovieInReviewApproveMutation,
  MovieInReviewApproveMutation$data,
} from "@generated/MovieInReviewApproveMutation.graphql";

const query = graphql`
  fragment MovieInReviewApproveData on MovieMetainfo {
    id
  }
`;

const mutation = graphql`
  mutation MovieInReviewApproveMutation($input: ApproveLabelingMutationInput!) {
    approveLabeling(input: $input) {
      success
      message
    }
  }
`;

export default function MovieInReviewApprove({
  movieMetainfoFragment,
}: {
  movieMetainfoFragment: MovieInReviewApproveData$key;
}): JSX.Element {
  const navigate = useNavigate();
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { id: movieMetainfoId } = useFragment(query, movieMetainfoFragment);
  const [commit] = useMutation<MovieInReviewApproveMutation>(mutation);

  const handleApprove = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const { success, message } = await new Promise<
      MovieInReviewApproveMutation$data["approveLabeling"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            movieMetainfoId,
          },
        },
        onCompleted: ({ approveLabeling }) => {
          resolve(approveLabeling);
        },
      });
    });
    if (success === true) {
      setMessage({
        mode: "toast",
        title: "承認しました",
      });
      navigate("/movie_in_reviews");
    } else {
      setMessage({
        mode: "error",
        title: "承認できませんでした",
        subline: message ?? "",
      });
      onRelease();
    }
  }, [
    commit,
    movieMetainfoId,
    navigate,
    setMessage,
    processing,
    onClick,
    onRelease,
  ]);
  return (
    <>
      <Stack direction="row" justifyContent="center">
        <Button
          disabled={processing}
          onClick={() => setConfirm(true)}
          variant="contained"
        >
          ラベリングを承認する
        </Button>
      </Stack>
      <Message
        closeText="キャンセル"
        confirmText="理解して、承認する"
        message="この操作は取り消せません。よろしいですか？"
        onClose={() => setConfirm(false)}
        onConfirm={handleApprove}
        title="ラベリングを承認しますか？"
        visible={confirm}
      />
    </>
  );
}
