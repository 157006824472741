/**
 * @generated SignedSource<<d3238888e1e1749a4ee436874df2db92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieInReviewAdmin$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationChatAdmin">;
  readonly " $fragmentType": "MovieInReviewAdmin";
};
export type MovieInReviewAdmin$key = {
  readonly " $data"?: MovieInReviewAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieInReviewAdmin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieInReviewAdmin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationChatAdmin"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "71ee41e2d05d154f6593a39d3153bfc2";

export default node;
