import { useState, useCallback, useMemo } from "react";
import {
  Box,
  Stack,
  Divider,
  IconButton,
  Chip,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler } from "react-relay/hooks";
import { useFragment, useMutation } from "react-relay";
import { CommentItemData$key } from "@generated/CommentItemData.graphql";
import { CommentItemAdmin$key } from "@generated/CommentItemAdmin.graphql";
import {
  CommentItemMutation,
  CommentItemMutation$data,
} from "@generated/CommentItemMutation.graphql";
import { dateFormat } from "@lib/utils/date";
import Icon from "@components/atoms/Icon";
import Text from "@components/atoms/Text";
import Avatar from "@components/atoms/Avatar";
import Loading from "@components/atoms/Loading";
import Message from "@components/molecules/Message";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import HtmlText from "@components/atoms/HtmlText";
import { isNil } from "@lib/utils/commons";
import { decodeId } from "@lib/utils/convertId";

const query = graphql`
  fragment CommentItemData on CommentContent {
    id
    content
    createdAt
    comment {
      id
      movieMetainfo {
        direction
      }
    }
    user {
      id
      name
      avatar {
        signedUrl
      }
    }
    movieClip {
      id
      swingImage {
        signedUrl
      }
      swingClassification {
        secondary
        swingFrame {
          name
        }
      }
    }
  }
`;

const adminQuery = graphql`
  fragment CommentItemAdmin on User {
    id
  }
`;

const mutation = graphql`
  mutation CommentItemMutation(
    $input: RemoveCommentContentMutationInput!
    $connections: [ID!]!
  ) {
    removeCommentContent(input: $input) {
      __typename
      ... on CommentContent {
        id @deleteEdge(connections: $connections)
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function CommentItem({
  commentFragment,
  adminFragment,
}: {
  commentFragment: CommentItemData$key;
  adminFragment: CommentItemAdmin$key;
}) {
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { id, content, createdAt, user, comment, movieClip } = useFragment(
    query,
    commentFragment
  );
  const admin = useFragment(adminQuery, adminFragment);
  const {
    movieMetainfo: { direction },
  } = comment;
  const { swingImage, swingClassification } = movieClip ?? {
    swingImage: null,
    swingClassification: null,
  };
  const [commit] = useMutation<CommentItemMutation>(mutation);
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const connectionID = ConnectionHandler.getConnectionID(
    comment.id,
    "Comment__commentContents"
  );
  const { boxHeight, boxWidth } = useMemo<{
    boxHeight: number;
    boxWidth: number;
  }>(() => {
    if (!isNil(direction) && direction === "portrait") {
      return {
        boxHeight: 340,
        boxWidth: 200,
      };
    }
    return {
      boxHeight: 150,
      boxWidth: 255,
    };
  }, [direction]);
  const handleRemove = useCallback(async () => {
    if (processing || admin.id !== user.id) {
      return;
    }
    onClick();
    setConfirm(false);
    const result = await new Promise<
      CommentItemMutation$data["removeCommentContent"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id,
          },
          connections: [connectionID],
        },
        onCompleted: ({ removeCommentContent }) => {
          resolve(removeCommentContent);
        },
      });
    });
    if (result.__typename === "UserError") {
      setMessage({
        mode: "error",
        title: result.message,
      });
    }
    onRelease();
  }, [
    commit,
    id,
    connectionID,
    admin,
    user,
    setMessage,
    processing,
    onClick,
    onRelease,
  ]);

  return (
    <Stack direction="column" sx={{ width: "100%" }}>
      <Stack
        alignItems="flex-start"
        direction="row"
        justifyContent="flex-start"
        spacing={2}
      >
        <Stack direction="column" spacing={1}>
          <Avatar url={user.avatar?.signedUrl} />
        </Stack>
        <Stack direction="column" flex={1} spacing={1}>
          <Text style={{ fontSize: 10 }}>ID:{decodeId(id)}</Text>
          <Stack direction="row" spacing={1}>
            <Text style={{ fontSize: 10 }} variant="subtitle2">
              {user.name}
            </Text>
            <Text style={{ fontSize: 10 }} variant="subtitle2">
              {dateFormat(createdAt, "M月D日 HH:mm")}
            </Text>
          </Stack>
          <Divider />
          <Text>
            <HtmlText text={content} />
          </Text>
          {!isNil(movieClip) && (
            <Card sx={{ width: boxWidth }}>
              <CardMedia
                component="img"
                height={boxHeight}
                image={swingImage?.signedUrl ?? ""}
              />
              <CardContent>
                <Stack
                  alignItems="flex-start"
                  direction="row"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                >
                  <Box sx={{ padding: "2px" }}>
                    <Chip
                      label={`ID: ${decodeId(movieClip.id)}`}
                      size="small"
                      variant="outlined"
                    />
                  </Box>

                  {!isNil(swingClassification) && (
                    <>
                      <Box sx={{ padding: "2px" }}>
                        <Chip
                          color="primary"
                          label={swingClassification.swingFrame.name}
                          size="small"
                          variant="outlined"
                        />
                      </Box>

                      {swingClassification.secondary === true && (
                        <Box sx={{ padding: "2px" }}>
                          <Chip
                            color="success"
                            label="予備フラグ"
                            size="small"
                            variant="outlined"
                          />
                        </Box>
                      )}
                    </>
                  )}
                </Stack>
              </CardContent>
            </Card>
          )}
        </Stack>
      </Stack>
      {admin.id === user.id && (
        <Stack direction="row" justifyContent="flex-end">
          <IconButton onClick={() => setConfirm(true)}>
            <Icon name="trash" size={16} />
          </IconButton>
        </Stack>
      )}
      <Message
        closeText="キャンセル"
        confirmText="削除する"
        message="この操作は取り消せません。よろしいですか？"
        onClose={() => setConfirm(false)}
        onConfirm={handleRemove}
        title="コメントを削除しますか？"
        visible={confirm}
      />
      {processing && <Loading mask />}
    </Stack>
  );
}
