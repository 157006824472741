import { useState } from "react";
import {
  Stack,
  Box,
  Chip,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  CardActions,
  Divider,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { CoachingCard$key } from "@generated/CoachingCard.graphql";
import Spacer from "@components/atoms/Spacer";
import useMovieMetainfo from "@lib/hooks/useMovieMetainfo";
import Avatar from "@components/atoms/Avatar";
import Icon from "@components/atoms/Icon";
import Video from "@components/atoms/Video";
import { decodeId } from "@lib/utils/convertId";

const query = graphql`
  fragment CoachingCard on MovieMetainfo {
    id
    thumbnail
    sourceUrl
    user {
      id
      name
      avatar {
        signedUrl
      }
    }
    ...useMovieMetainfo
  }
`;

export default function CoachingCard({
  movieFragment,
}: {
  movieFragment: CoachingCard$key;
}) {
  const data = useFragment(query, movieFragment);
  const { id, thumbnail, sourceUrl, user } = data;
  const metainfo = useMovieMetainfo({ movieMetainfoFragment: data });
  const navigate = useNavigate();
  const [exapnd, setExpand] = useState<boolean>(false);
  return (
    <>
      <Card
        sx={{
          maxWidth: 280,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <CardHeader
          avatar={<Avatar size={32} url={user?.avatar?.signedUrl} />}
          subheader={`ID: ${decodeId(id)}`}
          title={user?.name}
        />
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            height="250"
            image={thumbnail ?? ""}
            onClick={() => setExpand(true)}
          />
          <IconButton
            onClick={() => setExpand(true)}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <Icon name="play" size={24} />
          </IconButton>
        </Box>
        <CardContent>
          <Stack
            alignItems="flex-start"
            direction="row"
            flex={1}
            flexWrap="wrap"
          >
            {metainfo.length > 0 && (
              <>
                {metainfo.map((row) => (
                  <Box key={row} sx={{ padding: "2px" }}>
                    <Chip
                      color="primary"
                      label={row}
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                ))}
                <Spacer height={8} />
              </>
            )}
          </Stack>
        </CardContent>
        <Divider />
        <CardActions>
          <Stack flex={1}>
            <Button
              onClick={() => {
                navigate(`/movie_metainfos/${id}/labels`);
              }}
              variant="contained"
            >
              詳細
            </Button>
          </Stack>
        </CardActions>
      </Card>
      <Dialog onClose={() => setExpand(false)} open={exapnd}>
        <Stack alignItems="flex-end" justifyContent="center">
          <IconButton onClick={() => setExpand(false)}>
            <Icon name="close" size={24} />
          </IconButton>
        </Stack>
        <DialogContent>
          <Video src={sourceUrl} />
        </DialogContent>
      </Dialog>
    </>
  );
}
