import { useCallback } from "react";
import {
  Stack,
  Box,
  Chip,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  CardActions,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { MovieInReviewsCard$key } from "@generated/MovieInReviewsCard.graphql";
import { MovieInReviewsCardAdmin$key } from "@generated/MovieInReviewsCardAdmin.graphql";
import { MovieInReviewsCardMutation } from "@generated/MovieInReviewsCardMutation.graphql";
import Spacer from "@components/atoms/Spacer";
import Text from "@components/atoms/Text";
import Colors from "@constants/Colors";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import useMovieMetainfo from "@lib/hooks/useMovieMetainfo";
import Avatar from "@components/atoms/Avatar";
import { dateFormat } from "@lib/utils/date";

const query = graphql`
  fragment MovieInReviewsCard on MovieMetainfo {
    id
    thumbnail
    clipCount
    user {
      id
      name
      avatar {
        signedUrl
      }
    }
    reviewer {
      id
      name
    }
    labeledAt
    ...useMovieMetainfo
  }
`;

const adminQuery = graphql`
  fragment MovieInReviewsCardAdmin on User {
    id
  }
`;

const mutation = graphql`
  mutation MovieInReviewsCardMutation(
    $input: AssignMovieReviewerMutationInput!
  ) {
    assignMovieReviewer(input: $input) {
      id
      reviewer {
        id
        name
      }
    }
  }
`;

export default function MovieInReviewsCard({
  movieFragment,
  adminFragment,
}: {
  movieFragment: MovieInReviewsCard$key;
  adminFragment: MovieInReviewsCardAdmin$key;
}) {
  const { processing, onClick } = usePreventDoubleClick();
  const data = useFragment(query, movieFragment);
  const { id: adminId } = useFragment(adminQuery, adminFragment);
  const { id, thumbnail, clipCount, user, reviewer, labeledAt } = data;
  const metainfo = useMovieMetainfo({ movieMetainfoFragment: data });
  const navigate = useNavigate();
  const [commit] = useMutation<MovieInReviewsCardMutation>(mutation);
  const handleAssign = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    await new Promise<void>((resolve) => {
      commit({
        variables: {
          input: {
            id,
          },
        },
        onCompleted: () => {
          resolve();
        },
      });
    });
    navigate(`/movie_in_reviews/${id}`);
  }, [commit, id, navigate, processing, onClick]);

  return (
    <Card
      sx={{
        maxWidth: 280,
        marginBottom: 1,
        position: "relative",
        overflow: "hidden",
      }}
    >
      {reviewer?.id === adminId && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: Colors.orange,
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          <Text bold style={{ fontSize: 10, color: Colors.white }}>
            レビュー中
          </Text>
        </Box>
      )}
      <CardHeader
        avatar={<Avatar size={32} url={user?.avatar?.signedUrl} />}
        subheader={dateFormat(labeledAt, "ラベル日時: M月D日 HH:mm")}
        title={user?.name}
      />
      <CardMedia component="img" height="250" image={thumbnail ?? ""} />
      <CardContent>
        <Stack alignItems="flex-start" direction="row" flex={1} flexWrap="wrap">
          {metainfo.length > 0 ? (
            <>
              {metainfo.map((row) => (
                <Box key={row} sx={{ padding: "2px" }}>
                  <Chip
                    color="primary"
                    label={row}
                    size="small"
                    variant="outlined"
                  />
                </Box>
              ))}
              <Box sx={{ padding: "2px" }}>
                <Chip
                  color="primary"
                  label={`${clipCount}枚`}
                  size="small"
                  variant="outlined"
                />
              </Box>
              <Spacer height={8} />
            </>
          ) : (
            <Text bold color={Colors.orange}>
              メタ情報が登録されていません
            </Text>
          )}
        </Stack>
      </CardContent>
      <Divider />
      <CardActions>
        <Stack flex={1}>
          {reviewer === null && user?.id !== adminId ? (
            <Button onClick={handleAssign} variant="contained">
              レビューする
            </Button>
          ) : (
            <Button
              onClick={() => {
                navigate(`/movie_in_reviews/${id}`);
              }}
              variant="contained"
            >
              詳細
            </Button>
          )}
        </Stack>
      </CardActions>
    </Card>
  );
}
