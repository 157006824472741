import { useCallback, useState } from "react";
import {
  Stack,
  Box,
  Chip,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  CardActions,
  Divider,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieMetainfosCard$key } from "@generated/MovieMetainfosCard.graphql";
import Spacer from "@components/atoms/Spacer";
import Text from "@components/atoms/Text";
import Loading from "@components/atoms/Loading";
import Icon from "@components/atoms/Icon";
import Colors from "@constants/Colors";
import Avatar from "@components/atoms/Avatar";
import useMovieMetainfo from "@lib/hooks/useMovieMetainfo";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import Message from "@components/molecules/Message";
import useMessage from "@lib/hooks/useMessage";
import useMovieClipDownload from "@lib/hooks/useMovieClipDownload";
import { dateFormat } from "@lib/utils/date";
import { decodeId } from "@lib/utils/convertId";

const query = graphql`
  fragment MovieMetainfosCard on MovieMetainfo {
    id
    thumbnail
    clipCount
    approvedAt
    user {
      name
      avatar {
        signedUrl
      }
    }
    ...useMovieMetainfo
  }
`;

export default function MovieMetainfosCard({
  movieFragment,
}: {
  movieFragment: MovieMetainfosCard$key;
}) {
  const data = useFragment(query, movieFragment);
  const { id, thumbnail, clipCount, approvedAt, user } = data;
  const metainfo = useMovieMetainfo({ movieMetainfoFragment: data });
  const navigate = useNavigate();
  const [, setMessage] = useMessage();
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { download } = useMovieClipDownload();
  const onDownload = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    await download({
      movieMetainfoId: id,
      onError: (message: string) => {
        setMessage({
          mode: "error",
          title: message,
        });
      },
    });
    onRelease();
    setConfirm(false);
  }, [download, id, processing, onRelease, onClick, setMessage]);

  return (
    <>
      <Card sx={{ maxWidth: 280, marginBottom: 1 }}>
        <CardHeader
          avatar={<Avatar size={32} url={user?.avatar?.signedUrl} />}
          subheader={dateFormat(approvedAt, "承認日: M月D日 HH:mm")}
          title={user?.name}
        />
        <CardMedia
          component="img"
          height="250"
          image={thumbnail ?? ""}
          onClick={() => {
            navigate(`/movie_metainfos/${id}/labels`);
          }}
        />
        <CardContent>
          <Stack
            alignItems="flex-start"
            direction="row"
            flex={1}
            flexWrap="wrap"
          >
            <Box sx={{ padding: "2px" }}>
              <Chip
                color="default"
                label={`ID: ${decodeId(id)}`}
                size="small"
                variant="outlined"
              />
            </Box>
            <Spacer height={8} />
            {metainfo.length > 0 ? (
              <>
                {metainfo.map((row) => (
                  <Box key={row} sx={{ padding: "2px" }}>
                    <Chip
                      color="primary"
                      label={row}
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                ))}
                <Box sx={{ padding: "2px" }}>
                  <Chip
                    color="primary"
                    label={`${clipCount}枚`}
                    size="small"
                    variant="outlined"
                  />
                </Box>
                <Spacer height={8} />
              </>
            ) : (
              <Text bold color={Colors.orange}>
                メタ情報が登録されていません
              </Text>
            )}
          </Stack>
        </CardContent>
        <Divider />
        <CardActions>
          <Stack flex={1}>
            <Button
              onClick={() => {
                navigate(`/movie_metainfos/${id}/labels`);
              }}
              variant="contained"
            >
              詳細
            </Button>
          </Stack>
          <IconButton onClick={() => setConfirm(true)}>
            <Icon color={Colors.gray} name="download" size={32} />
          </IconButton>
        </CardActions>
      </Card>
      <Message
        closeText="キャンセル"
        confirmText="ダウンロードする"
        onClose={() => setConfirm(false)}
        onConfirm={onDownload}
        title="スイング画像をダウンロードしますか？"
        visible={confirm}
      />
      {processing && <Loading mask />}
    </>
  );
}
