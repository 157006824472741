import React, { useMemo, useState } from "react";
import { Stack, List, ListItem, Divider, Tabs, Tab } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment, usePaginationFragment } from "react-relay";
import { CommentContentPagination$key } from "@generated/CommentContentPagination.graphql";
import { CommentAdmin$key } from "@generated/CommentAdmin.graphql";
import { CommentQuery$key } from "@generated/CommentQuery.graphql";
import SwingPositionClassificationSummary from "@components/molecules/SwingPositionClassification/SwingPositionClassificationSummary";
import SwingPositionClassificationItem from "@components/molecules/SwingPositionClassification/SwingPositionClassificationItem";
import SwingPositionClassificationChat from "@components/molecules/SwingPositionClassification/SwingPositionClassificationChat";
import CommentPost from "@components/organisms/Comment/CommentPost";
import CommentItem from "@components/organisms/Comment/CommentItem";
import { isNil } from "@lib/utils/commons";
import Text from "@components/atoms/Text";

const query = graphql`
  fragment CommentContentPagination on Comment
  @refetchable(queryName: "CommentContentPaginationQuery")
  @argumentDefinitions(after: { type: "String" }, first: { type: "Int" }) {
    commentContents(first: $first, after: $after)
      @connection(key: "Comment__commentContents") {
      edges {
        node {
          id
          ...CommentItemData
        }
      }
      currentPage
      totalCount
      limit
    }
    movieMetainfo {
      user {
        id
      }
      reviewer {
        id
      }
      movieClips {
        id
        ...SwingPositionClassificationItemClip
      }
      ...SwingPositionClassificationSummary
      ...SwingPositionClassificationItemMeta
      ...SwingPositionClassificationChat
      ...CommentPostList
    }
    ...CommentPost
    commentUsers {
      user {
        id
      }
      lastReadAt
    }
  }
`;

const masterQuery = graphql`
  fragment CommentQuery on Query {
    ...SwingPositionClassificationItemQuery
    ...SwingPositionClassificationSummaryQuery
  }
`;

const adminQuery = graphql`
  fragment CommentAdmin on User {
    id
    ...CommentItemAdmin
    ...SwingPositionClassificationChatAdmin
  }
`;

export default function Comment({
  commentsFragment,
  adminFragment,
  masterFragment,
}: {
  commentsFragment: CommentContentPagination$key;
  adminFragment: CommentAdmin$key;
  masterFragment: CommentQuery$key;
}) {
  const { data } = usePaginationFragment(query, commentsFragment);
  const { edges } = data.commentContents;
  const { user, reviewer, movieClips } = data.movieMetainfo;
  const admin = useFragment<CommentAdmin$key>(adminQuery, adminFragment);
  const master = useFragment<CommentQuery$key>(masterQuery, masterFragment);
  const [tab, setTab] = useState<"comments" | "swings">("comments");
  const postable = useMemo<boolean>(() => {
    const targets: string[] = [];
    if (!isNil(user)) {
      targets.push(user.id);
    }
    if (!isNil(reviewer)) {
      targets.push(reviewer.id);
    }
    return targets.includes(admin.id);
  }, [reviewer, user, admin]);
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: "comments" | "swings"
  ) => {
    setTab(newValue);
    event.preventDefault();
  };

  return (
    <Stack direction="column" paddingX={2}>
      <SwingPositionClassificationSummary
        movieMetainfoFragment={data.movieMetainfo}
        swingPositionsFragment={master}
      />
      <Tabs onChange={handleChange} value={tab}>
        <Tab label="コメント" value="comments" />
        <Tab label="スイング画像" value="swings" />
      </Tabs>
      <Stack flex={1}>
        <List hidden={tab !== "comments"}>
          {edges.length > 0 ? (
            edges.map(({ node }) => (
              <React.Fragment key={node.id}>
                <ListItem>
                  <CommentItem adminFragment={admin} commentFragment={node} />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))
          ) : (
            <Text bold>コメントはありません</Text>
          )}
        </List>
        <List hidden={tab !== "swings"}>
          {movieClips.map((movieClip) => (
            <React.Fragment key={movieClip.id}>
              <ListItem>
                <SwingPositionClassificationItem
                  editable
                  masterFragment={master}
                  movieClipFragment={movieClip}
                  movieMetainfoFragment={data.movieMetainfo}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
          <SwingPositionClassificationChat
            adminFragment={admin}
            movieMetainfoFragment={data.movieMetainfo}
          />
        </List>
      </Stack>
      {postable && (
        <CommentPost
          commentFragment={data}
          movieFragment={data.movieMetainfo}
        />
      )}
    </Stack>
  );
}
