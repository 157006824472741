/**
 * @generated SignedSource<<c48d757e2023104265731c580f3e06f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieJobStatus = "assigned" | "closing" | "done" | "generating" | "ignored" | "in_working" | "waiting";
import { FragmentRefs } from "relay-runtime";
export type MovieJobConfirm$data = {
  readonly id: string;
  readonly movieMetainfo: {
    readonly id: string;
    readonly movieClips: ReadonlyArray<{
      readonly id: string;
      readonly swingClassification: {
        readonly id: string;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationItemClip">;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationChat" | "SwingPositionClassificationItemMeta" | "SwingPositionClassificationSummary">;
  };
  readonly status: MovieJobStatus;
  readonly " $fragmentType": "MovieJobConfirm";
};
export type MovieJobConfirm$key = {
  readonly " $data"?: MovieJobConfirm$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobConfirm">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieJobConfirm",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieMetainfo",
      "kind": "LinkedField",
      "name": "movieMetainfo",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SwingPositionClassificationItemMeta"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SwingPositionClassificationSummary"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SwingPositionClassificationChat"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MovieClip",
          "kind": "LinkedField",
          "name": "movieClips",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SwingClassification",
              "kind": "LinkedField",
              "name": "swingClassification",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SwingPositionClassificationItemClip"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MovieJob",
  "abstractKey": null
};
})();

(node as any).hash = "2e8692044e3339a98b43a3782d9b657e";

export default node;
