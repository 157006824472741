import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import MovieMetainfos from "@components/templates/MovieMetainfos";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { MovieMetainfosScreenQuery } from "@generated/MovieMetainfosScreenQuery.graphql";
import { PageLimit } from "@constants/App";
import usePaginationOffset from "@lib/hooks/usePaginationOffset";

const query = graphql`
  query MovieMetainfosScreenQuery(
    $first: Int!
    $after: String
    $status: MovieMetainfosStatus!
  ) {
    ...MovieMetainfosPagination
      @arguments(first: $first, after: $after, status: $status)
    ...MovieMetainfosQuery
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<MovieMetainfosScreenQuery>;
}) {
  const data = usePreloadedQuery<MovieMetainfosScreenQuery>(
    query,
    queryReference
  );
  return <MovieMetainfos masterFragment={data} movieFragment={data} />;
}

export default function MovieMetainfosScreen() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<MovieMetainfosScreenQuery>(query);
  const after = usePaginationOffset();
  useEffect(() => {
    loadQuery(
      {
        first: PageLimit,
        after,
        status: "approved",
      },
      {
        fetchPolicy: "network-only",
      }
    );
    return () => {
      disposeQuery();
    };
  }, [after, loadQuery, disposeQuery]);

  return (
    <ContentLayout title="スイング動画一覧">
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
