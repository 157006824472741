import { useEffect, useMemo, useRef, useState } from "react";
import { Stack } from "@mui/material";
import Text from "@components/atoms/Text";
import Icon from "@components/atoms/Icon";
import { BoundingBoxColors } from "@constants/Colors";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieMetainfoDetectionItem$key } from "@generated/MovieMetainfoDetectionItem.graphql";
import { MovieMetainfoDetectionItemDirection$key } from "@generated/MovieMetainfoDetectionItemDirection.graphql";
import styled from "styled-components";
import { isNil, getKeys } from "@lib/utils/commons";
import BoundingBox from "@components/atoms/BoundingBox";
import { ObjectDetectionObjectType } from "@constants/App";
import { decodeId } from "@lib/utils/convertId";

const query = graphql`
  fragment MovieMetainfoDetectionItem on MovieClip {
    id
    width
    height
    swingImage {
      id
      signedUrl
    }
    objectDetections {
      id
      objectType
      xmin
      ymin
      xmax
      ymax
    }
  }
`;

const infoQuery = graphql`
  fragment MovieMetainfoDetectionItemDirection on MovieMetainfo {
    direction
  }
`;

type BoxSize = {
  width: number;
  height: number;
};

export default function MovieMetainfoDetectionItem({
  movieMetainfoFragment,
  movieClipFragment,
}: {
  movieMetainfoFragment: MovieMetainfoDetectionItemDirection$key;
  movieClipFragment: MovieMetainfoDetectionItem$key;
}) {
  const { id, width, height, swingImage, objectDetections } =
    useFragment<MovieMetainfoDetectionItem$key>(query, movieClipFragment);
  const { direction } = useFragment<MovieMetainfoDetectionItemDirection$key>(
    infoQuery,
    movieMetainfoFragment
  );

  const boxRef = useRef<HTMLImageElement>(null);
  const [boxSize, setBoxSize] = useState<BoxSize | null>(null);
  const boxStyle = useMemo(() => {
    const styles: { [index: string]: string } = { position: "relative" };
    if (!isNil(direction) && direction === "portrait") {
      styles.maxWidth = "400px";
    } else if (direction === "square") {
      styles.maxWidth = "400px";
      styles.maxHeight = "400px";
    } else {
      styles.maxHeight = "400px";
    }
    return styles;
  }, [direction]);

  useEffect(() => {
    if (boxRef !== null && !isNil(boxRef.current)) {
      setBoxSize({
        width: boxRef.current.clientWidth,
        height: boxRef.current.clientHeight,
      });
    }
  }, [boxRef]);

  return (
    <Stack
      alignItems="flex-start"
      direction="row"
      flex={1}
      justifyContent="flex-start"
      spacing={2}
    >
      <Stack flex={1} sx={boxStyle}>
        <Image
          key={swingImage.id}
          ref={boxRef}
          src={swingImage.signedUrl ?? ""}
        />
        {boxSize !== null && !isNil(width) && !isNil(height) && (
          <BoundingBox
            boundingBoxes={objectDetections.map((detection) => ({
              type: detection.objectType,
              xmin: detection.xmin,
              ymin: detection.ymin,
              xmax: detection.xmax,
              ymax: detection.ymax,
            }))}
            boxSize={boxSize}
            originalSize={{ width, height }}
          />
        )}
      </Stack>
      <Stack alignItems="flex-start" direction="column" spacing={2}>
        <Text>ID: {decodeId(id)}</Text>
        {getKeys(ObjectDetectionObjectType).map((key) => (
          <Stack
            key={key}
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={1}
          >
            <Icon color={BoundingBoxColors[key]} name="square" size={20} />
            <Text>{ObjectDetectionObjectType[key]}</Text>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
