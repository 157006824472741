/**
 * @generated SignedSource<<f735b58052d7eed93ba4e6842cbbbdea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoData$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoDetection" | "MovieMetainfoLabelsData" | "MovieMetainfoRemandButton">;
  readonly " $fragmentType": "MovieMetainfoData";
};
export type MovieMetainfoData$key = {
  readonly " $data"?: MovieMetainfoData$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfoData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieMetainfoDetection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieMetainfoLabelsData"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieMetainfoRemandButton"
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};

(node as any).hash = "e39318da348e82973f148171a19a3d23";

export default node;
