import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieJob$key } from "@generated/MovieJob.graphql";
import { MovieJobQuery$key } from "@generated/MovieJobQuery.graphql";
import { MovieJobAdmin$key } from "@generated/MovieJobAdmin.graphql";
import MovieJobWorking from "@components/organisms/MovieJob/MovieJobWorking";
import MovieJobConfirm from "@components/organisms/MovieJob/MovieJobConfirm";

const query = graphql`
  fragment MovieJob on MovieJob {
    ...MovieJobWorking
    ...MovieJobConfirm
  }
`;

const adminQuery = graphql`
  fragment MovieJobAdmin on User {
    ...MovieJobConfirmAdmin
  }
`;

const masterQuery = graphql`
  fragment MovieJobQuery on Query {
    ...MovieJobWorkingQuery
    ...MovieJobConfirmQuery
  }
`;

export type TabType = "working" | "confirm";

export default function MovieJob({
  movieJobFragment,
  swingsFragment,
  adminFragment,
  tab,
}: {
  movieJobFragment: MovieJob$key;
  swingsFragment: MovieJobQuery$key;
  adminFragment: MovieJobAdmin$key;
  tab: TabType;
}) {
  const movieJob = useFragment<MovieJob$key>(query, movieJobFragment);
  const swings = useFragment<MovieJobQuery$key>(masterQuery, swingsFragment);
  const admin = useFragment<MovieJobAdmin$key>(adminQuery, adminFragment);

  return tab === "working" ? (
    <MovieJobWorking masterFragment={swings} movieJobFragment={movieJob} />
  ) : (
    <MovieJobConfirm
      adminFragment={admin}
      masterFragment={swings}
      movieJobFragment={movieJob}
    />
  );
}
