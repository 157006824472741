import { Stack, Box, Chip, Divider } from "@mui/material";
import Text from "@components/atoms/Text";
import HtmlText from "@components/atoms/HtmlText";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { CoachingMain$key } from "@generated/CoachingMain.graphql";
import { decodeId } from "@lib/utils/convertId";
import CoachingCard from "@components/organisms/Coaching/CoachingCard";
import Avatar from "@components/atoms/Avatar";

const query = graphql`
  fragment CoachingMain on Coaching {
    id
    content
    version
    name
    cause {
      content
      swingPosition {
        name
      }
    }
    movieJobs {
      id
      movieMetainfo {
        ...CoachingCard
      }
    }
  }
`;

export default function CoachingMain({
  coachingFragment,
}: {
  coachingFragment: CoachingMain$key;
}) {
  const { id, cause, content, version, name, movieJobs } =
    useFragment<CoachingMain$key>(query, coachingFragment);
  return (
    <Stack direction="column" spacing={2}>
      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        {movieJobs.map((movieJob) => (
          <Box
            key={movieJob.id}
            sx={{
              minWidth: "250px",
              padding: 1,
            }}
          >
            <CoachingCard movieFragment={movieJob.movieMetainfo} />
          </Box>
        ))}
      </Box>
      <Stack flex={1} spacing={2}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-start"
          spacing={1}
        >
          <Chip
            color="primary"
            label={`ID: ${decodeId(id)}`}
            size="small"
            variant="outlined"
          />
          <Chip
            color="warning"
            label={`ver${version}`}
            size="small"
            variant="outlined"
          />
          <Chip
            label={cause.swingPosition.name}
            size="small"
            variant="outlined"
          />
        </Stack>
        <Box>
          <Text bold variant="h2">
            {cause.content}
          </Text>
        </Box>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Avatar size={32} url="" />
          <Text>{name}</Text>
        </Stack>
        <Divider />
        <Box>{content !== null && <HtmlText text={content ?? ""} />}</Box>
      </Stack>
    </Stack>
  );
}
