/**
 * @generated SignedSource<<d6c41cc5b9a68d09de79393ae26866c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoDirection = "landscape" | "portrait" | "square";
import { FragmentRefs } from "relay-runtime";
export type CommentItemData$data = {
  readonly comment: {
    readonly id: string;
    readonly movieMetainfo: {
      readonly direction: MovieMetainfoDirection | null | undefined;
    };
  };
  readonly content: string;
  readonly createdAt: any;
  readonly id: string;
  readonly movieClip: {
    readonly id: string;
    readonly swingClassification: {
      readonly secondary: boolean;
      readonly swingFrame: {
        readonly name: string;
      };
    } | null | undefined;
    readonly swingImage: {
      readonly signedUrl: string | null | undefined;
    };
  } | null | undefined;
  readonly user: {
    readonly avatar: {
      readonly signedUrl: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentType": "CommentItemData";
};
export type CommentItemData$key = {
  readonly " $data"?: CommentItemData$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentItemData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signedUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentItemData",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Comment",
      "kind": "LinkedField",
      "name": "comment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MovieMetainfo",
          "kind": "LinkedField",
          "name": "movieMetainfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "direction",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Avatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieClip",
      "kind": "LinkedField",
      "name": "movieClip",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SwingImage",
          "kind": "LinkedField",
          "name": "swingImage",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SwingClassification",
          "kind": "LinkedField",
          "name": "swingClassification",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "secondary",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SwingFrame",
              "kind": "LinkedField",
              "name": "swingFrame",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CommentContent",
  "abstractKey": null
};
})();

(node as any).hash = "095e583d16d14ee3e0e102bfb6784625";

export default node;
