/**
 * @generated SignedSource<<af00139a5b896b2962e91b3d41d21c76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CloseMovieJobMutationInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type MovieJobConfirmMutation$variables = {
  input: CloseMovieJobMutationInput;
};
export type MovieJobConfirmMutation$data = {
  readonly closeMovieJob: {
    readonly success: boolean;
  };
};
export type MovieJobConfirmMutation = {
  response: MovieJobConfirmMutation$data;
  variables: MovieJobConfirmMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProcessResult",
    "kind": "LinkedField",
    "name": "closeMovieJob",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MovieJobConfirmMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MovieJobConfirmMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "49af286e7ef5c00d800f707ec93e0a43",
    "id": null,
    "metadata": {},
    "name": "MovieJobConfirmMutation",
    "operationKind": "mutation",
    "text": "mutation MovieJobConfirmMutation(\n  $input: CloseMovieJobMutationInput!\n) {\n  closeMovieJob(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "cee6029ee6764e676a80f1db8c5b77d2";

export default node;
