import React, { useCallback, useState } from "react";
import { ListItem, Divider, List, Stack, Button } from "@mui/material";
import { FilePicker } from "@components/molecules/TextInput";
import Text from "@components/atoms/Text";
import useMessage from "@lib/hooks/useMessage";
import { resolveError } from "@lib/utils/error";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { UploadableMap } from "relay-runtime";
import Colors from "@constants/Colors";
import Spacer from "@components/atoms/Spacer";
import Message from "@components/molecules/Message";
import Loading from "@components/atoms/Loading";
import { isNil } from "@lib/utils/commons";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import { MovieMetainfoDetection$key } from "@generated/MovieMetainfoDetection.graphql";
import {
  MovieMetainfoDetectionMutation,
  MovieMetainfoDetectionMutation$data,
} from "@generated/MovieMetainfoDetectionMutation.graphql";
import MovieMetainfoDetectionItem from "@components/organisms/MovieMetainfo/MovieMetainfoDetectionItem";
import useMovieClipDownload from "@lib/hooks/useMovieClipDownload";
import { decodeId } from "@lib/utils/convertId";

const query = graphql`
  fragment MovieMetainfoDetection on MovieMetainfo {
    id
    ...MovieMetainfoDetectionItemDirection
    movieClips {
      id
      ...MovieMetainfoDetectionItem
    }
  }
`;

const importXml = graphql`
  mutation MovieMetainfoDetectionMutation(
    $input: ImportObjectDetectionsMutationInput!
  ) {
    importObjectDetections(input: $input) {
      __typename
      ... on MovieMetainfo {
        id
        movieClips {
          id
          swingImage {
            id
            signedUrl
          }
          objectDetections {
            id
            objectType
            xmin
            ymin
            xmax
            ymax
          }
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function MovieMetainfoDetection({
  movieMetainfoFragment,
}: {
  movieMetainfoFragment: MovieMetainfoDetection$key;
}) {
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const movieMetainfo = useFragment<MovieMetainfoDetection$key>(
    query,
    movieMetainfoFragment
  );
  const { id, movieClips } = movieMetainfo;
  const { download } = useMovieClipDownload();
  const onDownload = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();

    await download({
      movieMetainfoId: id,
      onError: (message: string) => {
        setMessage({
          mode: "error",
          title: message,
        });
      },
    });
    onRelease();
    setConfirm(false);
  }, [id, download, processing, onRelease, onClick, setMessage]);

  const [commit] = useMutation<MovieMetainfoDetectionMutation>(importXml);
  const handleUload = useCallback(
    async (uploadables: UploadableMap | null) => {
      try {
        if (uploadables === null) {
          return;
        }
        const { type } = uploadables["variables.input.file"];
        if (isNil(type) || type !== "text/xml") {
          throw new Error("XMLファイルを選択してください");
        }
        if (processing) {
          return;
        }
        onClick();
        const result = await new Promise<
          MovieMetainfoDetectionMutation$data["importObjectDetections"]
        >((resolve) => {
          commit({
            variables: {
              input: {
                movieMetainfoId: id,
                file: null,
              },
            },
            uploadables,
            onCompleted({ importObjectDetections }) {
              resolve(importObjectDetections);
            },
          });
        });
        if (result.__typename === "UserError") {
          setMessage({
            mode: "error",
            title: "インポートできませんでした",
            subline: result.message,
          });
        } else {
          setMessage({
            mode: "toast",
            title: "インポートしました",
          });
        }
      } catch (e: unknown) {
        setMessage({
          title: "インポートできませんでした",
          subline:
            resolveError(e).message ??
            "XMLファイルをアップロードできませんでした",
          mode: "error",
        });
      } finally {
        onRelease();
      }
    },
    [commit, id, processing, onRelease, onClick, setMessage]
  );

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Stack flex={1}>
          <Text>ID:{decodeId(id)}</Text>
        </Stack>
        <Button
          color="secondary"
          onClick={() => setConfirm(true)}
          variant="contained"
        >
          スイング画像をダウンロード
        </Button>
        <FilePicker
          content={
            <Text color={Colors.white}>物体検出XMLをインポートする</Text>
          }
          onChange={handleUload}
          type="xml"
        />
      </Stack>
      <Spacer height={16} />
      <Divider />
      {movieClips.length > 0 ? (
        <List>
          {movieClips.map((movieClip) => (
            <React.Fragment key={movieClip.id}>
              <ListItem>
                <MovieMetainfoDetectionItem
                  movieClipFragment={movieClip}
                  movieMetainfoFragment={movieMetainfo}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Text>
          スイング画像がありません。ラベリングが完了したら表示されます。
        </Text>
      )}

      <Message
        closeText="キャンセル"
        confirmText="ダウンロードする"
        onClose={() => setConfirm(false)}
        onConfirm={onDownload}
        title="スイング画像をダウンロードしますか？"
        visible={confirm}
      />

      {processing && <Loading mask />}
    </>
  );
}
