/**
 * @generated SignedSource<<1a0edb9dbab9a09ea779ad6969631e18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentContentPagination$data = {
  readonly commentContents: {
    readonly currentPage: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"CommentItemData">;
      };
    }>;
    readonly limit: number;
    readonly totalCount: number;
  };
  readonly commentUsers: ReadonlyArray<{
    readonly lastReadAt: any | null | undefined;
    readonly user: {
      readonly id: string;
    };
  }>;
  readonly id: string;
  readonly movieMetainfo: {
    readonly movieClips: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationItemClip">;
    }>;
    readonly reviewer: {
      readonly id: string;
    } | null | undefined;
    readonly user: {
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CommentPostList" | "SwingPositionClassificationChat" | "SwingPositionClassificationItemMeta" | "SwingPositionClassificationSummary">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CommentPost">;
  readonly " $fragmentType": "CommentContentPagination";
};
export type CommentContentPagination$key = {
  readonly " $data"?: CommentContentPagination$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentContentPagination">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "commentContents"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./CommentContentPaginationQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "CommentContentPagination",
  "selections": [
    {
      "alias": "commentContents",
      "args": null,
      "concreteType": "CommentContentConnection",
      "kind": "LinkedField",
      "name": "__Comment__commentContents_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CommentContentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CommentContent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CommentItemData"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "limit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieMetainfo",
      "kind": "LinkedField",
      "name": "movieMetainfo",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "reviewer",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MovieClip",
          "kind": "LinkedField",
          "name": "movieClips",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SwingPositionClassificationItemClip"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SwingPositionClassificationSummary"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SwingPositionClassificationItemMeta"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SwingPositionClassificationChat"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommentPostList"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentPost"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentUser",
      "kind": "LinkedField",
      "name": "commentUsers",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastReadAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "Comment",
  "abstractKey": null
};
})();

(node as any).hash = "5295a9780403184ec5ef9d4cb156c453";

export default node;
