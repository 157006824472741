import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import MovieInReviews from "@components/templates/MovieInReviews";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { MovieInReviewsScreenQuery } from "@generated/MovieInReviewsScreenQuery.graphql";
import { PageLimit } from "@constants/App";
import usePaginationOffset from "@lib/hooks/usePaginationOffset";

const query = graphql`
  query MovieInReviewsScreenQuery(
    $first: Int!
    $after: String
    $status: MovieMetainfosStatus!
  ) {
    ...MovieInReviewsPagination
      @arguments(first: $first, after: $after, status: $status)
    admin {
      ...MovieInReviewsAdmin
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<MovieInReviewsScreenQuery>;
}) {
  const data = usePreloadedQuery<MovieInReviewsScreenQuery>(
    query,
    queryReference
  );
  return <MovieInReviews adminFragment={data.admin} movieFragment={data} />;
}

export default function MovieInReviewsScreen() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<MovieInReviewsScreenQuery>(query);
  const after = usePaginationOffset();
  useEffect(() => {
    loadQuery(
      {
        first: PageLimit,
        after,
        status: "in_review",
      },
      {
        fetchPolicy: "network-only",
      }
    );
    return () => {
      disposeQuery();
    };
  }, [after, loadQuery, disposeQuery]);

  return (
    <ContentLayout title="スイング動画レビュー一覧">
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
