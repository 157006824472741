/**
 * @generated SignedSource<<e9dcc6afebcc9e57f2f985885363aa7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoAdmin$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoLabelsAdmin">;
  readonly " $fragmentType": "MovieMetainfoAdmin";
};
export type MovieMetainfoAdmin$key = {
  readonly " $data"?: MovieMetainfoAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoAdmin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfoAdmin",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieMetainfoLabelsAdmin"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "e5b84cbac389d8b1f90ea51d9e3fc473";

export default node;
