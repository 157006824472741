/**
 * @generated SignedSource<<dda27d153d916ec6adaf9ebdefd82d72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieJobsIgnoredScreenQuery$variables = Record<PropertyKey, never>;
export type MovieJobsIgnoredScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobsIgnored" | "MovieJobsIgnoredSwingQuery">;
};
export type MovieJobsIgnoredScreenQuery = {
  response: MovieJobsIgnoredScreenQuery$data;
  variables: MovieJobsIgnoredScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MovieJobsIgnoredScreenQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MovieJobsIgnored"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MovieJobsIgnoredSwingQuery"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MovieJobsIgnoredScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MovieJob",
        "kind": "LinkedField",
        "name": "movieJobsIgnored",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ignoreReason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MovieMetainfo",
            "kind": "LinkedField",
            "name": "movieMetainfo",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Club",
                "kind": "LinkedField",
                "name": "club",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "swing",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sourceUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trimmingStart",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trimmingEnd",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "duration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "angle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "direction",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gender",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hand",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "place",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Club",
        "kind": "LinkedField",
        "name": "clubs",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "25daf137c93ebae8562fe768842bec37",
    "id": null,
    "metadata": {},
    "name": "MovieJobsIgnoredScreenQuery",
    "operationKind": "query",
    "text": "query MovieJobsIgnoredScreenQuery {\n  ...MovieJobsIgnored\n  ...MovieJobsIgnoredSwingQuery\n}\n\nfragment MovieJobsIgnored on Query {\n  movieJobsIgnored {\n    id\n    ...MovieJobsIgnoredItem\n  }\n}\n\nfragment MovieJobsIgnoredItem on MovieJob {\n  id\n  ignoreReason\n  status\n  movieMetainfo {\n    id\n    user {\n      name\n      id\n    }\n    club {\n      name\n      id\n    }\n    swing\n    ...SwingPositionClassificationMeta\n  }\n}\n\nfragment MovieJobsIgnoredItemSwingQuery on Query {\n  ...SwingPositionClassificationMetaQuery\n}\n\nfragment MovieJobsIgnoredSwingQuery on Query {\n  ...MovieJobsIgnoredItemSwingQuery\n}\n\nfragment SwingPositionClassificationMeta on MovieMetainfo {\n  id\n  sourceUrl\n  club {\n    id\n    name\n  }\n  trimmingStart\n  trimmingEnd\n  duration\n  width\n  height\n  angle\n  direction\n  gender\n  hand\n  place\n  swing\n}\n\nfragment SwingPositionClassificationMetaQuery on Query {\n  clubs {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "7af9e91225f015a7caaa43913666698f";

export default node;
