/**
 * @generated SignedSource<<883fdb38d26c2de1f8ebbd9bba1bcbb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieJobQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobConfirmQuery" | "MovieJobWorkingQuery">;
  readonly " $fragmentType": "MovieJobQuery";
};
export type MovieJobQuery$key = {
  readonly " $data"?: MovieJobQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieJobQuery",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieJobWorkingQuery"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieJobConfirmQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "9268e3c8a79b339acfa9bd3597067fb4";

export default node;
