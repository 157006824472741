import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieJobsIgnored$key } from "@generated/MovieJobsIgnored.graphql";
import { MovieJobsIgnoredSwingQuery$key } from "@generated/MovieJobsIgnoredSwingQuery.graphql";
import MovieJobsIgnoredItem from "@components/organisms/MovieJobsIgnored/MovieJobsIgnoredItem";

const query = graphql`
  fragment MovieJobsIgnored on Query {
    movieJobsIgnored {
      id
      ...MovieJobsIgnoredItem
    }
  }
`;

const swingQuery = graphql`
  fragment MovieJobsIgnoredSwingQuery on Query {
    ...MovieJobsIgnoredItemSwingQuery
  }
`;

export default function MovieJobsIgnored({
  movieJobsFragment,
  swingsFragment,
}: {
  movieJobsFragment: MovieJobsIgnored$key;
  swingsFragment: MovieJobsIgnoredSwingQuery$key;
}) {
  const { movieJobsIgnored } = useFragment(query, movieJobsFragment);
  const data = useFragment(swingQuery, swingsFragment);
  return (
    <Table aria-label="simple table" sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell component="th">ID</TableCell>
          <TableCell component="th">作業者</TableCell>
          <TableCell component="th">スイング</TableCell>
          <TableCell component="th">クラブ名</TableCell>
          <TableCell component="th">除外理由</TableCell>
          <TableCell component="th">詳細</TableCell>
          <TableCell component="th">操作</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {movieJobsIgnored.map((movieJob) => (
          <MovieJobsIgnoredItem
            key={movieJob.id}
            movieJobFragment={movieJob}
            swingsFragment={data}
          />
        ))}
      </TableBody>
    </Table>
  );
}
