import React, { useEffect, useRef } from "react";
import { Box, ListItem, Divider, List, Stack } from "@mui/material";
import Text from "@components/atoms/Text";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieInReviewData$key } from "@generated/MovieInReviewData.graphql";
import { MovieInReviewAdmin$key } from "@generated/MovieInReviewAdmin.graphql";
import { MovieInReviewQuery$key } from "@generated/MovieInReviewQuery.graphql";
import useQueryParams from "@lib/hooks/useQueryParams";
import useScroll from "@lib/hooks/useScroll";
import Colors from "@constants/Colors";
import { PageAnchorName } from "@constants/App";
import SwingPositionClassificationItem from "@components/molecules/SwingPositionClassification/SwingPositionClassificationItem";
import SwingPositionClassificationSummary from "@components/molecules/SwingPositionClassification/SwingPositionClassificationSummary";
import SwingPositionClassificationChat from "@components/molecules/SwingPositionClassification/SwingPositionClassificationChat";
import MovieInReviewApprove from "@components/organisms/MovieInReview/MovieInReviewApprove";
import MovieInReviewRemand from "@components/organisms/MovieInReview/MovieInReviewRemand";

const query = graphql`
  fragment MovieInReviewData on MovieMetainfo {
    id
    reviewer {
      id
    }
    ...SwingPositionClassificationSummary
    ...SwingPositionClassificationChat
    ...SwingPositionClassificationItemMeta
    ...MovieInReviewRemandData
    ...MovieInReviewApproveData
    movieClips {
      id
      ...SwingPositionClassificationItemClip
    }
  }
`;

const adminQuery = graphql`
  fragment MovieInReviewAdmin on User {
    id
    ...SwingPositionClassificationChatAdmin
  }
`;

const masterQuery = graphql`
  fragment MovieInReviewQuery on Query {
    swingPositions {
      id
      name
    }
    ...SwingPositionClassificationItemQuery
    ...SwingPositionClassificationSummaryQuery
  }
`;

export default function MovieInReview({
  movieMetainfoFragment,
  adminFragment,
  masterFragment,
}: {
  movieMetainfoFragment: MovieInReviewData$key;
  adminFragment: MovieInReviewAdmin$key;
  masterFragment: MovieInReviewQuery$key;
}): JSX.Element {
  const listItemRef = useRef<HTMLLIElement | null>(null);
  const admin = useFragment(adminQuery, adminFragment);
  const data = useFragment(query, movieMetainfoFragment);
  const { movieClips, reviewer } = data;
  const master = useFragment<MovieInReviewQuery$key>(
    masterQuery,
    masterFragment
  );
  const param = useQueryParams(PageAnchorName);
  const anchor = param[PageAnchorName];
  const { scrollTo } = useScroll();
  useEffect(() => {
    if (listItemRef.current !== null) {
      scrollTo(listItemRef);
    }
  }, [scrollTo]);
  return (
    <>
      <SwingPositionClassificationSummary
        movieMetainfoFragment={data}
        swingPositionsFragment={master}
      />
      {movieClips.length > 0 ? (
        <Box>
          <List>
            {movieClips.map((movieClip) => (
              <React.Fragment key={movieClip.id}>
                <ListItem
                  ref={(ref) => {
                    if (ref !== null && anchor === movieClip.id) {
                      listItemRef.current = ref;
                    }
                  }}
                  sx={{
                    backgroundColor:
                      anchor === movieClip.id ? Colors.yellow10 : undefined,
                  }}
                >
                  <SwingPositionClassificationItem
                    editable
                    masterFragment={master}
                    movieClipFragment={movieClip}
                    movieMetainfoFragment={data}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          {reviewer?.id === admin.id && (
            <Stack direction="row" justifyContent="center" spacing={2}>
              <MovieInReviewApprove movieMetainfoFragment={data} />
              <MovieInReviewRemand movieMetainfoFragment={data} />
            </Stack>
          )}
        </Box>
      ) : (
        <Text>レビュー中のラベル付はありません</Text>
      )}
      <SwingPositionClassificationChat
        adminFragment={admin}
        movieMetainfoFragment={data}
      />
    </>
  );
}
