/**
 * @generated SignedSource<<7e720aad117288b6b208bada74642ef3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SwingFrameAngle = "back" | "front";
import { FragmentRefs } from "relay-runtime";
export type MovieJobItemQuery$data = {
  readonly swingPositions: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly swingFrames: ReadonlyArray<{
      readonly angle: SwingFrameAngle;
      readonly id: string;
      readonly name: string;
    }>;
  }>;
  readonly " $fragmentType": "MovieJobItemQuery";
};
export type MovieJobItemQuery$key = {
  readonly " $data"?: MovieJobItemQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobItemQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieJobItemQuery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingPosition",
      "kind": "LinkedField",
      "name": "swingPositions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SwingFrame",
          "kind": "LinkedField",
          "name": "swingFrames",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "angle",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "aac8b891d5151b19f6174ed1c0855bc7";

export default node;
