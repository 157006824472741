import React from "react";
import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment, usePaginationFragment } from "react-relay";
import { MovieMetainfosPagination$key } from "@generated/MovieMetainfosPagination.graphql";
import { MovieMetainfosQuery$key } from "@generated/MovieMetainfosQuery.graphql";
import OffsetPagination from "@components/atoms/OffsetPagination";
import MovieMetainfosCard from "@components/organisms/MovieMetainfos/MovieMetainfosCard";
import MovieMetainfosSearch, {
  SearchInput,
} from "@components/organisms/MovieMetainfos/MovieMetainfosSearch";
import Spacer from "@components/atoms/Spacer";
import { PageLimit } from "@constants/App";

const query = graphql`
  fragment MovieMetainfosPagination on Query
  @refetchable(queryName: "MovieMetainfosPaginationQuery")
  @argumentDefinitions(
    after: { type: "String" }
    first: { type: "Int" }
    status: { type: "MovieMetainfosStatus" }
    angle: { type: "MovieMetainfoAngle" }
    swing: { type: "MovieMetainfoSwing" }
    clubId: { type: "ID" }
    userId: { type: "ID" }
    reviewerId: { type: "ID" }
    ids: { type: "String" }
  ) {
    movieMetainfos(
      first: $first
      after: $after
      status: $status
      angle: $angle
      swing: $swing
      clubId: $clubId
      userId: $userId
      reviewerId: $reviewerId
      ids: $ids
    ) @connection(key: "MovieMetainfosList__movieMetainfos") {
      edges {
        node {
          id
          ...MovieMetainfosCard
        }
      }
      currentPage
      totalCount
      limit
    }
  }
`;

const masterQuery = graphql`
  fragment MovieMetainfosQuery on Query {
    ...MovieMetainfosSearchQuery
  }
`;

export default function MovieMetainfos({
  movieFragment,
  masterFragment,
}: {
  movieFragment: MovieMetainfosPagination$key;
  masterFragment: MovieMetainfosQuery$key;
}) {
  const master = useFragment<MovieMetainfosQuery$key>(
    masterQuery,
    masterFragment
  );
  const { data, refetch } = usePaginationFragment(query, movieFragment);
  const { edges, currentPage, limit, totalCount } = data.movieMetainfos;
  const handleSearch = async (params: SearchInput) => {
    const { ids, angle, clubId, userId, swing, reviewerId } = params;
    refetch(
      {
        status: "approved",
        first: PageLimit,
        angle,
        clubId,
        userId,
        swing,
        reviewerId,
        ids,
      },
      { fetchPolicy: "network-only" }
    );
  };
  return (
    <>
      <MovieMetainfosSearch onSearch={handleSearch} queryFragment={master} />
      <Stack alignItems="flex-start" direction="row" flexWrap="wrap">
        {edges.map(({ node }) => (
          <React.Fragment key={node.id}>
            <MovieMetainfosCard key={node.id} movieFragment={node} />
            <Spacer width={8} />
          </React.Fragment>
        ))}
      </Stack>
      <OffsetPagination
        currentPage={currentPage}
        limit={limit}
        path="movie_metainfos"
        totalCount={totalCount}
      />
    </>
  );
}
