/**
 * @generated SignedSource<<828ba8e50fb291310e611f5d2e8adf1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachingMain$data = {
  readonly cause: {
    readonly content: string;
    readonly swingPosition: {
      readonly name: string;
    };
  };
  readonly content: string | null | undefined;
  readonly id: string;
  readonly movieJobs: ReadonlyArray<{
    readonly id: string;
    readonly movieMetainfo: {
      readonly " $fragmentSpreads": FragmentRefs<"CoachingCard">;
    };
  }>;
  readonly name: string | null | undefined;
  readonly version: number;
  readonly " $fragmentType": "CoachingMain";
};
export type CoachingMain$key = {
  readonly " $data"?: CoachingMain$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachingMain">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachingMain",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Cause",
      "kind": "LinkedField",
      "name": "cause",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SwingPosition",
          "kind": "LinkedField",
          "name": "swingPosition",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieJob",
      "kind": "LinkedField",
      "name": "movieJobs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MovieMetainfo",
          "kind": "LinkedField",
          "name": "movieMetainfo",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CoachingCard"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Coaching",
  "abstractKey": null
};
})();

(node as any).hash = "2a6964d8b0cd09e046381c2567ec51d1";

export default node;
