/**
 * @generated SignedSource<<f36472fd9b8f3a16ac23a5c85bdf214b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoRemandButton$data = {
  readonly id: string;
  readonly " $fragmentType": "MovieMetainfoRemandButton";
};
export type MovieMetainfoRemandButton$key = {
  readonly " $data"?: MovieMetainfoRemandButton$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoRemandButton">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfoRemandButton",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};

(node as any).hash = "51811c3ef9eb8add559155f662e82441";

export default node;
