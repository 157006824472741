/**
 * @generated SignedSource<<dc882ef01ad30276a6e03cdac7612ab9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieInReviewApproveData$data = {
  readonly id: string;
  readonly " $fragmentType": "MovieInReviewApproveData";
};
export type MovieInReviewApproveData$key = {
  readonly " $data"?: MovieInReviewApproveData$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieInReviewApproveData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieInReviewApproveData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};

(node as any).hash = "0ed9f251cbbfd375ece787fc2a586017";

export default node;
