/**
 * @generated SignedSource<<2bb301a0e1b2bb8c7e0b772eaf4a24f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieJobsScreenQuery$variables = Record<PropertyKey, never>;
export type MovieJobsScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobs">;
};
export type MovieJobsScreenQuery = {
  response: MovieJobsScreenQuery$data;
  variables: MovieJobsScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MovieJobsScreenQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MovieJobs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MovieJobsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MovieJob",
        "kind": "LinkedField",
        "name": "movieJobs",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assignedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reservedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "generatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MovieMetainfo",
            "kind": "LinkedField",
            "name": "movieMetainfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Club",
                "kind": "LinkedField",
                "name": "club",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "angle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "swing",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca58382585ccaed2594b9316bf6bf3e3",
    "id": null,
    "metadata": {},
    "name": "MovieJobsScreenQuery",
    "operationKind": "query",
    "text": "query MovieJobsScreenQuery {\n  ...MovieJobs\n}\n\nfragment MovieJobs on Query {\n  movieJobs {\n    id\n    status\n    assignedAt\n    reservedAt\n    generatedAt\n    user {\n      name\n      id\n    }\n    movieMetainfo {\n      club {\n        name\n        id\n      }\n      angle\n      swing\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eec3108d47e5e778ea011536582f0701";

export default node;
