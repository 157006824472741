/**
 * @generated SignedSource<<98ef5605c931f52f25523814d2e168c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MovieJobStatus = "assigned" | "closing" | "done" | "generating" | "ignored" | "in_working" | "waiting";
export type UpdateMovieJobMutationInput = {
  clientMutationId?: string | null | undefined;
  id: string;
  ignoreReason?: string | null | undefined;
  status: MovieJobStatus;
};
export type MovieJobsIgnoredItemMutation$variables = {
  input: UpdateMovieJobMutationInput;
};
export type MovieJobsIgnoredItemMutation$data = {
  readonly updateMovieJob: {
    readonly __typename: "MovieJob";
    readonly id: string;
    readonly ignoreReason: string | null | undefined;
    readonly movieMetainfo: {
      readonly id: string;
      readonly user: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
    };
    readonly status: MovieJobStatus;
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type MovieJobsIgnoredItemMutation = {
  response: MovieJobsIgnoredItemMutation$data;
  variables: MovieJobsIgnoredItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updateMovieJob",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ignoreReason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MovieMetainfo",
            "kind": "LinkedField",
            "name": "movieMetainfo",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "MovieJob",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UserError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MovieJobsIgnoredItemMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MovieJobsIgnoredItemMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3c7e8f85b9cb90940feac31f92a7a456",
    "id": null,
    "metadata": {},
    "name": "MovieJobsIgnoredItemMutation",
    "operationKind": "mutation",
    "text": "mutation MovieJobsIgnoredItemMutation(\n  $input: UpdateMovieJobMutationInput!\n) {\n  updateMovieJob(input: $input) {\n    __typename\n    ... on MovieJob {\n      id\n      ignoreReason\n      status\n      movieMetainfo {\n        id\n        user {\n          id\n          name\n        }\n      }\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a8147adeb68bb95802b1f7376a707a56";

export default node;
