import React, { useCallback, useState, useMemo } from "react";
import {
  Box,
  Card,
  List,
  ListItem,
  Divider,
  Stack,
  Button,
} from "@mui/material";
import Text from "@components/atoms/Text";
import Message from "@components/molecules/Message";
import Loading from "@components/atoms/Loading";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import SwingPositionClassificationItem from "@components/molecules/SwingPositionClassification/SwingPositionClassificationItem";
import SwingPositionClassificationSummary from "@components/molecules/SwingPositionClassification/SwingPositionClassificationSummary";
import SwingPositionClassificationChat from "@components/molecules/SwingPositionClassification/SwingPositionClassificationChat";
import Spacer from "@components/atoms/Spacer";
import { MovieJobConfirm$key } from "@generated/MovieJobConfirm.graphql";
import { MovieJobConfirmQuery$key } from "@generated/MovieJobConfirmQuery.graphql";
import { MovieJobConfirmAdmin$key } from "@generated/MovieJobConfirmAdmin.graphql";
import {
  MovieJobConfirmMutation,
  MovieJobConfirmMutation$data,
} from "@generated/MovieJobConfirmMutation.graphql";
import Colors from "@constants/Colors";
import { MaxClipCount, MinmunLabelingCount } from "@constants/App";
import { Closing } from "@components/organisms/MovieJob/hints";
import Content from "@components/atoms/Content";

const query = graphql`
  fragment MovieJobConfirm on MovieJob {
    id
    status
    movieMetainfo {
      id
      ...SwingPositionClassificationItemMeta
      ...SwingPositionClassificationSummary
      ...SwingPositionClassificationChat
      movieClips {
        id
        swingClassification {
          id
        }
        ...SwingPositionClassificationItemClip
      }
    }
  }
`;

const adminQuery = graphql`
  fragment MovieJobConfirmAdmin on User {
    ...SwingPositionClassificationChatAdmin
  }
`;

const masterQuery = graphql`
  fragment MovieJobConfirmQuery on Query {
    ...SwingPositionClassificationItemQuery
    ...SwingPositionClassificationSummaryQuery
  }
`;

const mutation = graphql`
  mutation MovieJobConfirmMutation($input: CloseMovieJobMutationInput!) {
    closeMovieJob(input: $input) {
      success
    }
  }
`;

export default function MovieJobConfirm({
  movieJobFragment,
  masterFragment,
  adminFragment,
}: {
  movieJobFragment: MovieJobConfirm$key;
  masterFragment: MovieJobConfirmQuery$key;
  adminFragment: MovieJobConfirmAdmin$key;
}) {
  const admin = useFragment<MovieJobConfirmAdmin$key>(
    adminQuery,
    adminFragment
  );
  const movieJob = useFragment<MovieJobConfirm$key>(query, movieJobFragment);
  const master = useFragment<MovieJobConfirmQuery$key>(
    masterQuery,
    masterFragment
  );
  const { id, status, movieMetainfo } = movieJob;
  const { movieClips } = movieMetainfo;
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [commit] = useMutation<MovieJobConfirmMutation>(mutation);
  const sumOFLabels = useMemo<number>(
    () => movieClips.filter((clip) => clip.swingClassification !== null).length,
    [movieClips]
  );
  const handleClose = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const result = await new Promise<
      MovieJobConfirmMutation$data["closeMovieJob"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id,
          },
        },
        onCompleted: ({ closeMovieJob }) => {
          resolve(closeMovieJob);
        },
      });
    });
    if (result.success === false) {
      setMessage({
        mode: "error",
        title: "処理に失敗しました",
        subline: "処理に失敗しました",
      });
      setConfirm(false);
      onRelease();
    } else {
      window.location.href = "/movie_jobs";
    }
  }, [commit, id, setMessage, processing, onClick, onRelease]);

  const onConfirm = useCallback(() => {
    if (movieClips.length === 0) {
      setMessage({
        mode: "error",
        title: "ラベル付けされた画像がありません",
        subline: "ラベル付を行ってください",
      });
    } else if (movieClips.length > MaxClipCount) {
      setMessage({
        mode: "error",
        title: `ラベル付けされた画像は${MaxClipCount}枚以下にしてください`,
        subline: "厳選して不要なラベル付け画像は削除してください",
      });
    } else {
      setConfirm(true);
    }
  }, [movieClips, setMessage]);

  return (
    <Box>
      <Card sx={{ padding: 2, backgroundColor: Colors.blue20 }}>
        <Text bold>
          作業内容を確認して、よろしければ「このスイング動画のラベル付を完了する」を押してください。
        </Text>
        <Spacer height={8} />
        {Closing.map((hint) => (
          <Content
            key={hint.title}
            column={hint.title}
            columnWidth={60}
            value={hint.value}
          />
        ))}
      </Card>

      <Spacer height={24} />
      <SwingPositionClassificationSummary
        movieMetainfoFragment={movieMetainfo}
        swingPositionsFragment={master}
      />
      <Spacer height={24} />

      {movieClips.length === 0 ? (
        <Text>ラベル付けされた画像がありません。ラベル付を行ってください</Text>
      ) : (
        <List>
          {movieClips.map((movieClip) => (
            <React.Fragment key={movieClip.id}>
              <ListItem>
                <SwingPositionClassificationItem
                  editable
                  masterFragment={master}
                  movieClipFragment={movieClip}
                  movieMetainfoFragment={movieMetainfo}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      )}
      <Spacer height={24} />
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={1}
      >
        <Button
          disabled={sumOFLabels < MinmunLabelingCount || status === "closing"}
          onClick={onConfirm}
          variant="contained"
        >
          {status === "closing" ? "完了処理中..." : "ラベル付け作業を完了する"}
        </Button>
        <Text variant="subtitle2">
          最低{MinmunLabelingCount}個以上ラベル付けしてください
        </Text>
      </Stack>
      <SwingPositionClassificationChat
        adminFragment={admin}
        movieMetainfoFragment={movieMetainfo}
      />
      <Message
        closeText="キャンセル"
        confirmText="理解して、完了する"
        message="完了すると、このスイング動画のラベル付けはできなくなります。よろしいですか？"
        onClose={() => setConfirm(false)}
        onConfirm={handleClose}
        title="ラベル付け作業を完了しますか？"
        visible={confirm}
      />
      {processing && <Loading color={Colors.white} mask />}
    </Box>
  );
}
