import React, { useMemo } from "react";
import { Stack, List, ListItem, Divider } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment, usePaginationFragment } from "react-relay";
import { CommentModalContentPagination$key } from "@generated/CommentModalContentPagination.graphql";
import { CommentModalAdmin$key } from "@generated/CommentModalAdmin.graphql";
import CommentPost from "@components/organisms/Comment/CommentPost";
import CommentItem from "@components/organisms/Comment/CommentItem";
import { isNil } from "@lib/utils/commons";
import Text from "@components/atoms/Text";

const query = graphql`
  fragment CommentModalContentPagination on Comment
  @refetchable(queryName: "CommentModalContentPaginationQuery")
  @argumentDefinitions(after: { type: "String" }, first: { type: "Int" }) {
    commentContents(first: $first, after: $after)
      @connection(key: "Comment__commentContents") {
      edges {
        node {
          id
          ...CommentItemData
        }
      }
      currentPage
      totalCount
      limit
    }
    movieMetainfo {
      user {
        id
      }
      reviewer {
        id
      }
      ...CommentPostList
    }
    ...CommentPost
  }
`;

const adminQuery = graphql`
  fragment CommentModalAdmin on User {
    id
    ...CommentItemAdmin
  }
`;

export default function CommentModal({
  commentsFragment,
  adminFragment,
}: {
  commentsFragment: CommentModalContentPagination$key;
  adminFragment: CommentModalAdmin$key;
}) {
  const { data } = usePaginationFragment(query, commentsFragment);
  const { edges } = data.commentContents;
  const { user, reviewer } = data.movieMetainfo;
  const admin = useFragment<CommentModalAdmin$key>(adminQuery, adminFragment);
  const postable = useMemo<boolean>(() => {
    const targets: string[] = [];
    if (!isNil(user)) {
      targets.push(user.id);
    }
    if (!isNil(reviewer)) {
      targets.push(reviewer.id);
    }
    return targets.includes(admin.id);
  }, [reviewer, user, admin]);

  return (
    <Stack direction="column" paddingX={2}>
      <Stack flex={1}>
        {edges.length > 0 ? (
          <List>
            {edges.map(({ node }) => (
              <React.Fragment key={node.id}>
                <ListItem>
                  <CommentItem adminFragment={admin} commentFragment={node} />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Text bold>コメントはありません</Text>
        )}
      </Stack>
      {postable && (
        <CommentPost
          commentFragment={data}
          movieFragment={data.movieMetainfo}
        />
      )}
    </Stack>
  );
}
