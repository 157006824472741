/**
 * @generated SignedSource<<0831ff2464486ba2be9d76998176bde7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgressScreenQuery$variables = Record<PropertyKey, never>;
export type ProgressScreenQuery$data = {
  readonly progress: {
    readonly " $fragmentSpreads": FragmentRefs<"ProgressData">;
  };
};
export type ProgressScreenQuery = {
  response: ProgressScreenQuery$data;
  variables: ProgressScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "classifiedCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProgressScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Progress",
        "kind": "LinkedField",
        "name": "progress",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProgressData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProgressScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Progress",
        "kind": "LinkedField",
        "name": "progress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SwingFrame",
            "kind": "LinkedField",
            "name": "swingFrames",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "angle",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Avatar",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signedUrl",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastClassifiedCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inReviewCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reviewedCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6bca6f8f30f827f32d1af8e4eb30c1ad",
    "id": null,
    "metadata": {},
    "name": "ProgressScreenQuery",
    "operationKind": "query",
    "text": "query ProgressScreenQuery {\n  progress {\n    ...ProgressData\n  }\n}\n\nfragment ProgressData on Progress {\n  swingFrames {\n    id\n    ...ProgressFrame\n  }\n  users {\n    id\n    ...ProgressUser\n  }\n}\n\nfragment ProgressFrame on SwingFrame {\n  id\n  name\n  angle\n  classifiedCount\n}\n\nfragment ProgressUser on User {\n  name\n  avatar {\n    signedUrl\n    id\n  }\n  classifiedCount\n  lastClassifiedCount\n  inReviewCount\n  reviewedCount\n}\n"
  }
};
})();

(node as any).hash = "9b8ca18aff1ec99793cf30e5c7c3d600";

export default node;
