/**
 * @generated SignedSource<<2494f94cf00a6d3bb1fdec353db1563e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoDirection = "landscape" | "portrait" | "square";
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoDetectionItemDirection$data = {
  readonly direction: MovieMetainfoDirection | null | undefined;
  readonly " $fragmentType": "MovieMetainfoDetectionItemDirection";
};
export type MovieMetainfoDetectionItemDirection$key = {
  readonly " $data"?: MovieMetainfoDetectionItemDirection$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoDetectionItemDirection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfoDetectionItemDirection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "direction",
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};

(node as any).hash = "77da928cb2a1ef4da9b2a1c21619da79";

export default node;
