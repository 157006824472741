import { useCallback, useState } from "react";
import { Button, Stack } from "@mui/material";
import Loading from "@components/atoms/Loading";
import { useNavigate } from "react-router-dom";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import useMessage from "@lib/hooks/useMessage";
import Message from "@components/molecules/Message";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { MovieInReviewRemandData$key } from "@generated/MovieInReviewRemandData.graphql";
import {
  MovieInReviewRemandMutation,
  MovieInReviewRemandMutation$data,
} from "@generated/MovieInReviewRemandMutation.graphql";
import Colors from "@constants/Colors";

const query = graphql`
  fragment MovieInReviewRemandData on MovieMetainfo {
    id
  }
`;

const mutation = graphql`
  mutation MovieInReviewRemandMutation(
    $input: RemandMovieMetainfoMutationInput!
  ) {
    remandMovieMetainfo(input: $input) {
      success
      message
    }
  }
`;

export default function MovieInReviewRemand({
  movieMetainfoFragment,
}: {
  movieMetainfoFragment: MovieInReviewRemandData$key;
}): JSX.Element {
  const navigate = useNavigate();
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { id } = useFragment(query, movieMetainfoFragment);
  const [commit] = useMutation<MovieInReviewRemandMutation>(mutation);
  const handleRemand = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    setConfirm(false);
    const { success, message } = await new Promise<
      MovieInReviewRemandMutation$data["remandMovieMetainfo"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id,
          },
        },
        onCompleted: ({ remandMovieMetainfo }) => {
          resolve(remandMovieMetainfo);
        },
      });
    });
    if (success === true) {
      setMessage({
        mode: "toast",
        title: "差戻しました",
      });
      navigate("/movie_in_reviews");
    } else {
      setMessage({
        mode: "error",
        title: "差戻せませんでした",
        subline: message ?? "",
      });
      onRelease();
    }
  }, [commit, id, navigate, setMessage, processing, onClick, onRelease]);
  return (
    <>
      <Stack direction="row" justifyContent="center">
        <Button
          color="error"
          disabled={processing}
          onClick={() => setConfirm(true)}
          variant="contained"
        >
          ラベリングを差戻す
        </Button>
      </Stack>
      {processing && <Loading color={Colors.white} mask />}
      <Message
        closeText="キャンセル"
        confirmText="理解して、差戻す"
        message="差戻すとスイング画像生成からやり直しになり、ラベルやコメントはすべて削除されます。よろしいですか？"
        onClose={() => setConfirm(false)}
        onConfirm={handleRemand}
        title="ラベリングを差戻しますか？"
        visible={confirm}
      />
    </>
  );
}
