import { useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import Message from "@components/molecules/Message";
import Loading from "@components/atoms/Loading";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import Icon from "@components/atoms/Icon";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { MovieMetainfoRemandButton$key } from "@generated/MovieMetainfoRemandButton.graphql";
import {
  MovieMetainfoRemandButtonMutation,
  MovieMetainfoRemandButtonMutation$data,
} from "@generated/MovieMetainfoRemandButtonMutation.graphql";
import Colors from "@constants/Colors";

const query = graphql`
  fragment MovieMetainfoRemandButton on MovieMetainfo {
    id
  }
`;

const mutation = graphql`
  mutation MovieMetainfoRemandButtonMutation(
    $input: RemandMovieMetainfoMutationInput!
  ) {
    remandMovieMetainfo(input: $input) {
      success
    }
  }
`;

export default function MovieMetainfoRemandButton({
  movieMetainfoFragment,
}: {
  movieMetainfoFragment: MovieMetainfoRemandButton$key;
}) {
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { id } = useFragment<MovieMetainfoRemandButton$key>(
    query,
    movieMetainfoFragment
  );
  const [commit] = useMutation<MovieMetainfoRemandButtonMutation>(mutation);
  const handleRemand = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const result = await new Promise<
      MovieMetainfoRemandButtonMutation$data["remandMovieMetainfo"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id,
          },
        },
        onCompleted: ({ remandMovieMetainfo }) => {
          resolve(remandMovieMetainfo);
        },
      });
    });
    if (result.success === false) {
      setMessage({
        mode: "error",
        title: "処理に失敗しました",
        subline: "処理に失敗しました",
      });
      setConfirm(false);
      onRelease();
    } else {
      window.location.href = "/movie_metainfos";
    }
  }, [commit, id, onClick, onRelease, processing, setMessage]);
  return (
    <>
      <IconButton onClick={() => setConfirm(true)}>
        <Icon name="trash" size={32} />
      </IconButton>
      <Message
        closeText="キャンセル"
        confirmText="理解して、差戻す"
        message="差戻すとスイング画像生成からやり直しになり、ラベルやコメントはすべて削除されます。よろしいですか？"
        onClose={() => setConfirm(false)}
        onConfirm={handleRemand}
        title="スイング動画を差戻しますか？"
        visible={confirm}
      />
      {processing && <Loading color={Colors.white} mask />}
    </>
  );
}
