/**
 * @generated SignedSource<<28b5bab912429b1e0d8ee37c255d82c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemandMovieMetainfoMutationInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type MovieInReviewRemandMutation$variables = {
  input: RemandMovieMetainfoMutationInput;
};
export type MovieInReviewRemandMutation$data = {
  readonly remandMovieMetainfo: {
    readonly message: string | null | undefined;
    readonly success: boolean;
  };
};
export type MovieInReviewRemandMutation = {
  response: MovieInReviewRemandMutation$data;
  variables: MovieInReviewRemandMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProcessResult",
    "kind": "LinkedField",
    "name": "remandMovieMetainfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MovieInReviewRemandMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MovieInReviewRemandMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "25780afd8cd11f56c30f57211c257216",
    "id": null,
    "metadata": {},
    "name": "MovieInReviewRemandMutation",
    "operationKind": "mutation",
    "text": "mutation MovieInReviewRemandMutation(\n  $input: RemandMovieMetainfoMutationInput!\n) {\n  remandMovieMetainfo(input: $input) {\n    success\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "70a914a2a0a7abbc3cfdf6eed40a4211";

export default node;
