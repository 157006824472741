/**
 * @generated SignedSource<<f27abdfdad4476a43683048b1ede5b44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ObjectDetectionObjectType = "ball" | "head" | "shaft";
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoDetectionItem$data = {
  readonly height: number | null | undefined;
  readonly id: string;
  readonly objectDetections: ReadonlyArray<{
    readonly id: string;
    readonly objectType: ObjectDetectionObjectType;
    readonly xmax: number;
    readonly xmin: number;
    readonly ymax: number;
    readonly ymin: number;
  }>;
  readonly swingImage: {
    readonly id: string;
    readonly signedUrl: string | null | undefined;
  };
  readonly width: number | null | undefined;
  readonly " $fragmentType": "MovieMetainfoDetectionItem";
};
export type MovieMetainfoDetectionItem$key = {
  readonly " $data"?: MovieMetainfoDetectionItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoDetectionItem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfoDetectionItem",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingImage",
      "kind": "LinkedField",
      "name": "swingImage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signedUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ObjectDetection",
      "kind": "LinkedField",
      "name": "objectDetections",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "objectType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "xmin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ymin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "xmax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ymax",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MovieClip",
  "abstractKey": null
};
})();

(node as any).hash = "0c3a50ae6575e95ca0584baf27499bcb";

export default node;
