/**
 * @generated SignedSource<<b66e7d42027edf1f44ec2fbf1fa9fa68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieJobStatus = "assigned" | "closing" | "done" | "generating" | "ignored" | "in_working" | "waiting";
export type MovieMetainfoAngle = "back" | "front";
export type MovieMetainfoSwing = "full" | "half" | "quarter";
import { FragmentRefs } from "relay-runtime";
export type MovieJobs$data = {
  readonly movieJobs: ReadonlyArray<{
    readonly assignedAt: any | null | undefined;
    readonly generatedAt: any | null | undefined;
    readonly id: string;
    readonly movieMetainfo: {
      readonly angle: MovieMetainfoAngle | null | undefined;
      readonly club: {
        readonly name: string;
      } | null | undefined;
      readonly swing: MovieMetainfoSwing | null | undefined;
    };
    readonly reservedAt: any | null | undefined;
    readonly status: MovieJobStatus;
    readonly user: {
      readonly name: string;
    } | null | undefined;
  }>;
  readonly " $fragmentType": "MovieJobs";
};
export type MovieJobs$key = {
  readonly " $data"?: MovieJobs$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobs">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieJobs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieJob",
      "kind": "LinkedField",
      "name": "movieJobs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reservedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "generatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MovieMetainfo",
          "kind": "LinkedField",
          "name": "movieMetainfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Club",
              "kind": "LinkedField",
              "name": "club",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "angle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "swing",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "a49e3823ca6cad956719505ba7573627";

export default node;
