/**
 * @generated SignedSource<<8c5278797fb8aa6aa58579f82166dca2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieJobStatus = "assigned" | "closing" | "done" | "generating" | "ignored" | "in_working" | "waiting";
import { FragmentRefs } from "relay-runtime";
export type MovieJobIgnore$data = {
  readonly id: string;
  readonly status: MovieJobStatus;
  readonly " $fragmentType": "MovieJobIgnore";
};
export type MovieJobIgnore$key = {
  readonly " $data"?: MovieJobIgnore$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobIgnore">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieJobIgnore",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "MovieJob",
  "abstractKey": null
};

(node as any).hash = "3a14f9ca96c9cdd465e433af96f1b792";

export default node;
