import { TableCell } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ProgressFrame$key } from "@generated/ProgressFrame.graphql";
import { SwingFrameAngle } from "@constants/App";
import { Link } from "react-router-dom";

const query = graphql`
  fragment ProgressFrame on SwingFrame {
    id
    name
    angle
    classifiedCount
  }
`;

export default function ProgressFrame({
  progressFragment,
}: {
  progressFragment: ProgressFrame$key;
}) {
  const { id, name, angle, classifiedCount } = useFragment(
    query,
    progressFragment
  );
  return (
    <>
      <TableCell>{SwingFrameAngle[angle]}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{classifiedCount}件</TableCell>
      <TableCell>
        <Link to={`/swing_frames/${id}/movies`}>詳細</Link>
      </TableCell>
    </>
  );
}
