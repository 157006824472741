import { useCallback, useState } from "react";
import {
  Button,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useMutation, useFragment } from "react-relay";
import Loading from "@components/atoms/Loading";
import { Form } from "@components/molecules/TextInput";
import Text from "@components/atoms/Text";
import Spacer from "@components/atoms/Spacer";
import Icon from "@components/atoms/Icon";
import useMessage from "@lib/hooks/useMessage";
import { useNavigate } from "react-router-dom";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import { MovieJobIgnore$key } from "@generated/MovieJobIgnore.graphql";
import {
  MovieJobIgnoreMutation,
  MovieJobIgnoreMutation$data,
} from "@generated/MovieJobIgnoreMutation.graphql";
import Colors from "@constants/Colors";

const query = graphql`
  fragment MovieJobIgnore on MovieJob {
    id
    status
  }
`;

const mutation = graphql`
  mutation MovieJobIgnoreMutation($input: UpdateMovieJobMutationInput!) {
    updateMovieJob(input: $input) {
      __typename
      ... on MovieJob {
        id
        ignoreReason
        status
        movieMetainfo {
          id
          user {
            id
            name
          }
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function MovieJobIgnore({
  movieJobFragment,
}: {
  movieJobFragment: MovieJobIgnore$key;
}) {
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [ignoreReason, setIgnoreReason] = useState<string>("");
  const [, setMessage] = useMessage();
  const { id, status } = useFragment<MovieJobIgnore$key>(
    query,
    movieJobFragment
  );
  const [commit] = useMutation<MovieJobIgnoreMutation>(mutation);
  const { processing, onClick, onRelease } = usePreventDoubleClick();

  const handleIgnore = useCallback(async () => {
    if (processing || status !== "assigned") {
      return;
    }
    onClick();
    const result = await new Promise<
      MovieJobIgnoreMutation$data["updateMovieJob"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id,
            ignoreReason,
            status: "ignored",
          },
        },
        onCompleted: ({ updateMovieJob }) => {
          resolve(updateMovieJob);
        },
      });
    });
    if (result.__typename !== "MovieJob") {
      setMessage({
        mode: "error",
        title:
          result.__typename === "UserError"
            ? result.message
            : "保存できませんでした",
      });
      onRelease();
    } else {
      navigate("/movie_jobs");
    }
  }, [
    id,
    ignoreReason,
    status,
    commit,
    setMessage,
    processing,
    onClick,
    onRelease,
    navigate,
  ]);

  return (
    <Stack
      alignItems="flex-end"
      direction="column"
      justifyContent="center"
      spacing={2}
    >
      <Button
        color="error"
        disabled={processing || status === "generating"}
        onClick={() => setConfirm(true)}
      >
        このスイング動画を除外する
      </Button>
      <Text variant="subtitle2">
        動画がスローモーションの場合など、ラベリングができない場合は除外してください
      </Text>
      <Dialog onClose={() => setConfirm(false)} open={confirm}>
        <Stack direction="row" justifyContent="flex-end">
          <IconButton onClick={() => setConfirm(false)}>
            <Icon name="close" size={24} />
          </IconButton>
        </Stack>
        <DialogContent>
          <Form>
            <Stack direction="column" justifyContent="flex-start" spacing={2}>
              <TextField
                inputProps={{ inputMode: "text" }}
                label="除外理由"
                multiline
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIgnoreReason(event.target.value);
                }}
                placeholder="除外理由を入力"
                style={{
                  width: "100%",
                  margin: "0px",
                }}
                type="text"
                value={ignoreReason}
              />
              <Stack alignItems="flex-end" direction="row">
                <Button
                  disabled={ignoreReason.length === 0}
                  onClick={handleIgnore}
                  variant="contained"
                >
                  この動画をラベリング対象から除外する
                </Button>
              </Stack>
              <Spacer height={18} />
            </Stack>
          </Form>
        </DialogContent>
      </Dialog>
      {processing && <Loading color={Colors.white} mask />}
    </Stack>
  );
}
