/**
 * @generated SignedSource<<061cb30eaaf801ce820c50d67eff85c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentAdmin$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CommentItemAdmin" | "SwingPositionClassificationChatAdmin">;
  readonly " $fragmentType": "CommentAdmin";
};
export type CommentAdmin$key = {
  readonly " $data"?: CommentAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentAdmin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentAdmin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentItemAdmin"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationChatAdmin"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "29df2ffe15ecbd29eada365167ff2af2";

export default node;
