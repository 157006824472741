/**
 * @generated SignedSource<<e0caa98e3d9e691802b8ebc9a802972b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoLabelsAdmin$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationChatAdmin">;
  readonly " $fragmentType": "MovieMetainfoLabelsAdmin";
};
export type MovieMetainfoLabelsAdmin$key = {
  readonly " $data"?: MovieMetainfoLabelsAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoLabelsAdmin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfoLabelsAdmin",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationChatAdmin"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4505cfdfc432be105a9b4dcd58c8b039";

export default node;
