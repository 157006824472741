import React, { useEffect, useRef } from "react";
import { ListItem, Divider, List } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieMetainfoLabelsData$key } from "@generated/MovieMetainfoLabelsData.graphql";
import { MovieMetainfoLabelsQuery$key } from "@generated/MovieMetainfoLabelsQuery.graphql";
import { MovieMetainfoLabelsAdmin$key } from "@generated/MovieMetainfoLabelsAdmin.graphql";
import SwingPositionClassificationItem from "@components/molecules/SwingPositionClassification/SwingPositionClassificationItem";
import SwingPositionClassificationSummary from "@components/molecules/SwingPositionClassification/SwingPositionClassificationSummary";
import SwingPositionClassificationChat from "@components/molecules/SwingPositionClassification/SwingPositionClassificationChat";
import Spacer from "@components/atoms/Spacer";
import useQueryParams from "@lib/hooks/useQueryParams";
import useScroll from "@lib/hooks/useScroll";
import Colors from "@constants/Colors";
import { PageAnchorName } from "@constants/App";

const query = graphql`
  fragment MovieMetainfoLabelsData on MovieMetainfo {
    ...SwingPositionClassificationItemMeta
    ...SwingPositionClassificationSummary
    ...SwingPositionClassificationChat
    movieClips {
      id
      ...SwingPositionClassificationItemClip
    }
  }
`;

const adminQuery = graphql`
  fragment MovieMetainfoLabelsAdmin on User {
    ...SwingPositionClassificationChatAdmin
  }
`;

const masterQuery = graphql`
  fragment MovieMetainfoLabelsQuery on Query {
    ...SwingPositionClassificationItemQuery
    ...SwingPositionClassificationSummaryQuery
  }
`;

export default function MovieMetainfoLabels({
  adminFragment,
  movieMetainfoFragment,
  masterFragment,
}: {
  adminFragment: MovieMetainfoLabelsAdmin$key;
  movieMetainfoFragment: MovieMetainfoLabelsData$key;
  masterFragment: MovieMetainfoLabelsQuery$key;
}) {
  const admin = useFragment<MovieMetainfoLabelsAdmin$key>(
    adminQuery,
    adminFragment
  );
  const data = useFragment<MovieMetainfoLabelsData$key>(
    query,
    movieMetainfoFragment
  );
  const listItemRef = useRef<HTMLLIElement | null>(null);
  const { movieClips } = data;
  const master = useFragment<MovieMetainfoLabelsQuery$key>(
    masterQuery,
    masterFragment
  );
  const param = useQueryParams(PageAnchorName);
  const anchor = param[PageAnchorName];
  const { scrollTo } = useScroll();
  useEffect(() => {
    if (listItemRef.current !== null) {
      scrollTo(listItemRef);
    }
  }, [scrollTo]);

  return (
    <>
      <SwingPositionClassificationSummary
        movieMetainfoFragment={data}
        swingPositionsFragment={master}
      />
      <Spacer height={16} />
      <Divider />
      <List>
        {movieClips.map((movieClip) => (
          <React.Fragment key={movieClip.id}>
            <ListItem
              ref={(ref) => {
                if (ref !== null && anchor === movieClip.id) {
                  listItemRef.current = ref;
                }
              }}
              sx={{
                backgroundColor:
                  anchor === movieClip.id ? Colors.yellow10 : undefined,
              }}
            >
              <SwingPositionClassificationItem
                editable
                masterFragment={master}
                movieClipFragment={movieClip}
                movieMetainfoFragment={data}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <SwingPositionClassificationChat
        adminFragment={admin}
        movieMetainfoFragment={data}
      />
    </>
  );
}
