/**
 * @generated SignedSource<<e4f8f779eb49c2e304b31e3c9abbe97a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieJob$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobConfirm" | "MovieJobWorking">;
  readonly " $fragmentType": "MovieJob";
};
export type MovieJob$key = {
  readonly " $data"?: MovieJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieJob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieJob",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieJobWorking"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieJobConfirm"
    }
  ],
  "type": "MovieJob",
  "abstractKey": null
};

(node as any).hash = "3bf5f958b3c45e28fd860b5053341b52";

export default node;
