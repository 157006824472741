/**
 * @generated SignedSource<<04fa674d82d3c8db1ace810afb74b343>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfosCard$data = {
  readonly approvedAt: any | null | undefined;
  readonly clipCount: number;
  readonly id: string;
  readonly thumbnail: string | null | undefined;
  readonly user: {
    readonly avatar: {
      readonly signedUrl: string | null | undefined;
    } | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"useMovieMetainfo">;
  readonly " $fragmentType": "MovieMetainfosCard";
};
export type MovieMetainfosCard$key = {
  readonly " $data"?: MovieMetainfosCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfosCard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfosCard",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clipCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Avatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signedUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMovieMetainfo"
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};

(node as any).hash = "52e1d660286e57b8ead0561b2533b21f";

export default node;
