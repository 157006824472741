import {
  NotificationsNoneOutlined,
  AddOutlined,
  PermIdentityOutlined,
  LogoutOutlined,
  MenuOutlined,
  CloseOutlined,
  HelpOutlineOutlined,
  MoreVertOutlined,
  CloudUploadOutlined,
  BrokenImageOutlined,
  EditOutlined,
  DeleteOutlineOutlined,
  ReplayOutlined,
  CheckOutlined,
  CheckBoxOutlined,
  ArrowUpwardOutlined,
  ArrowDownwardOutlined,
  ArrowDropDownOutlined,
  CloudDownload,
  Square,
  ShareOutlined,
  PlayCircleFilledWhiteOutlined,
  BookmarksOutlined,
  LiveTvOutlined,
  MenuBookOutlined,
  MailOutlineOutlined,
  LockOutlined,
  SettingsOutlined,
  ChatOutlined,
  SearchOutlined,
  HomeOutlined,
  ZoomOutMapOutlined,
} from "@mui/icons-material";
import { useMemo } from "react";
import { useMediaQuery } from "@mui/material";
import Colors from "@constants/Colors";

export type IconName =
  | "account"
  | "add"
  | "bell"
  | "book"
  | "chat"
  | "check"
  | "checked"
  | "close"
  | "down"
  | "download"
  | "dropdown"
  | "edit"
  | "email"
  | "expand"
  | "help"
  | "home"
  | "image"
  | "labeling"
  | "lock"
  | "logout"
  | "play"
  | "reload"
  | "menu"
  | "more"
  | "search"
  | "setting"
  | "share"
  | "square"
  | "trash"
  | "upload"
  | "up"
  | "video";

export default function Icon({
  name,
  size,
  color,
}: {
  name: IconName;
  size: number;
  color?: string;
}) {
  const isDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const iconColor = useMemo(() => {
    if (color !== undefined) {
      return color;
    }
    if (isDarkMode) {
      return Colors.white;
    }
    return Colors.black;
  }, [isDarkMode, color]);

  switch (name) {
    case "account":
      return <PermIdentityOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "add":
      return <AddOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "bell":
      return (
        <NotificationsNoneOutlined sx={{ fontSize: size, color: iconColor }} />
      );
    case "book":
      return <MenuBookOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "chat":
      return <ChatOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "check":
      return <CheckOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "checked":
      return <CheckBoxOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "close":
      return <CloseOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "down":
      return (
        <ArrowDownwardOutlined sx={{ fontSize: size, color: iconColor }} />
      );
    case "download":
      return <CloudDownload sx={{ fontSize: size, color: iconColor }} />;
    case "dropdown":
      return (
        <ArrowDropDownOutlined sx={{ fontSize: size, color: iconColor }} />
      );
    case "edit":
      return <EditOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "email":
      return <MailOutlineOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "expand":
      return <ZoomOutMapOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "help":
      return <HelpOutlineOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "home":
      return <HomeOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "image":
      return <BrokenImageOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "labeling":
      return <BookmarksOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "lock":
      return <LockOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "logout":
      return <LogoutOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "play":
      return (
        <PlayCircleFilledWhiteOutlined
          sx={{ fontSize: size, color: iconColor }}
        />
      );
    case "reload":
      return <ReplayOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "menu":
      return <MenuOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "more":
      return <MoreVertOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "trash":
      return (
        <DeleteOutlineOutlined sx={{ fontSize: size, color: iconColor }} />
      );
    case "search":
      return <SearchOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "setting":
      return <SettingsOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "share":
      return <ShareOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "square":
      return <Square sx={{ fontSize: size, color: iconColor }} />;
    case "upload":
      return <CloudUploadOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "up":
      return <ArrowUpwardOutlined sx={{ fontSize: size, color: iconColor }} />;
    case "video":
      return <LiveTvOutlined sx={{ fontSize: size, color: iconColor }} />;
    default:
      return null;
  }
}
