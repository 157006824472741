import { useCallback, useState } from "react";
import {
  Button,
  Stack,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Message from "@components/molecules/Message";
import Loading from "@components/atoms/Loading";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { MovieJobs$key } from "@generated/MovieJobs.graphql";
import {
  MovieJobsMutation,
  MovieJobsMutation$data,
} from "@generated/MovieJobsMutation.graphql";
import { dateFormat } from "@lib/utils/date";
import { decodeId } from "@lib/utils/convertId";
import { Link } from "react-router-dom";
import Text from "@components/atoms/Text";
import {
  MovieJobStatus,
  MovieMetainfoAngle,
  MovieMetainfoSwing,
} from "@constants/App";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import Colors from "@constants/Colors";
import { isNil } from "@lib/utils/commons";

const query = graphql`
  fragment MovieJobs on Query {
    movieJobs {
      id
      status
      assignedAt
      reservedAt
      generatedAt
      user {
        name
      }
      movieMetainfo {
        club {
          name
        }
        angle
        swing
      }
    }
  }
`;

const mutation = graphql`
  mutation MovieJobsMutation($input: AssignMovieJobsMutationInput!) {
    assignMovieJobs(input: $input) {
      success
      message
    }
  }
`;

export default function MovieJobs({
  movieJobsFragment,
}: {
  movieJobsFragment: MovieJobs$key;
}) {
  const { movieJobs } = useFragment(query, movieJobsFragment);
  const [, setMessage] = useMessage();
  const [commit] = useMutation<MovieJobsMutation>(mutation);
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [confirm, setConfirm] = useState<boolean>(false);
  const handleGenerate = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const result = await new Promise<MovieJobsMutation$data["assignMovieJobs"]>(
      (resolve) => {
        commit({
          variables: {
            input: {},
          },
          onCompleted: ({ assignMovieJobs }) => {
            resolve(assignMovieJobs);
          },
        });
      }
    );
    if (result.success === false) {
      setMessage({
        mode: "error",
        title: result.message ?? "生成できませんでした",
      });
      onRelease();
      setConfirm(false);
    } else {
      window.location.reload();
    }
  }, [commit, setMessage, processing, onClick, onRelease]);

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          disabled={processing}
          onClick={() => setConfirm(true)}
          variant="contained"
        >
          スイングポジション動画をアサインする
        </Button>
      </Stack>
      <Divider />
      {movieJobs.length > 0 ? (
        <Table aria-label="simple table" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell component="th">ID</TableCell>
              <TableCell component="th">メタ情報</TableCell>
              <TableCell component="th">スタータス</TableCell>
              <TableCell component="th">生成予約日</TableCell>
              <TableCell component="th">画像生成日</TableCell>
              <TableCell component="th">詳細</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {movieJobs.map((movieJob) => {
              const { movieMetainfo } = movieJob;
              const labels = [];
              if (movieMetainfo !== null) {
                if (!isNil(movieMetainfo.club)) {
                  labels.push(movieMetainfo.club.name);
                }
                if (!isNil(movieMetainfo.angle)) {
                  labels.push(MovieMetainfoAngle[movieMetainfo.angle]);
                }
                if (!isNil(movieMetainfo.swing)) {
                  labels.push(MovieMetainfoSwing[movieMetainfo.swing]);
                }
              }
              return (
                <TableRow key={movieJob.id}>
                  <TableCell>{decodeId(movieJob.id)}</TableCell>
                  <TableCell>
                    {labels.length > 0 ? labels.join("/") : "未登録"}
                  </TableCell>
                  <TableCell>{MovieJobStatus[movieJob.status]}</TableCell>
                  <TableCell>
                    {dateFormat(movieJob.reservedAt, "M月D日 HH:mm")}
                  </TableCell>
                  <TableCell>
                    {dateFormat(movieJob.generatedAt, "M月D日 HH:mm")}
                  </TableCell>
                  <TableCell>
                    <Link to={`/movie_jobs/${movieJob.id}`}>詳細</Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Text>
          作業中のスイング画像がありません。「スイングポジション画像を生成する」をクリックして、スイング動画からフレームレートごとに画像を生成してください。
        </Text>
      )}
      <Message
        closeText="キャンセル"
        confirmText="アサインする"
        onClose={() => setConfirm(false)}
        onConfirm={handleGenerate}
        title="作業動画を自分にアサインします"
        visible={confirm}
      />
      {processing && <Loading color={Colors.white} mask />}
    </Stack>
  );
}
