/**
 * @generated SignedSource<<a0497f7848570a154f6203ef52410457>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ObjectDetectionObjectType = "ball" | "head" | "shaft";
export type ImportObjectDetectionsMutationInput = {
  clientMutationId?: string | null | undefined;
  file: any;
  movieMetainfoId: string;
};
export type MovieMetainfoDetectionMutation$variables = {
  input: ImportObjectDetectionsMutationInput;
};
export type MovieMetainfoDetectionMutation$data = {
  readonly importObjectDetections: {
    readonly __typename: "MovieMetainfo";
    readonly id: string;
    readonly movieClips: ReadonlyArray<{
      readonly id: string;
      readonly objectDetections: ReadonlyArray<{
        readonly id: string;
        readonly objectType: ObjectDetectionObjectType;
        readonly xmax: number;
        readonly xmin: number;
        readonly ymax: number;
        readonly ymin: number;
      }>;
      readonly swingImage: {
        readonly id: string;
        readonly signedUrl: string | null | undefined;
      };
    }>;
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type MovieMetainfoDetectionMutation = {
  response: MovieMetainfoDetectionMutation$data;
  variables: MovieMetainfoDetectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "importObjectDetections",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MovieClip",
            "kind": "LinkedField",
            "name": "movieClips",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SwingImage",
                "kind": "LinkedField",
                "name": "swingImage",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signedUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ObjectDetection",
                "kind": "LinkedField",
                "name": "objectDetections",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "objectType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "xmin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ymin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "xmax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ymax",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "MovieMetainfo",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UserError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MovieMetainfoDetectionMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MovieMetainfoDetectionMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "abcfcf679fd1e447b62dfe25e1b6ca6d",
    "id": null,
    "metadata": {},
    "name": "MovieMetainfoDetectionMutation",
    "operationKind": "mutation",
    "text": "mutation MovieMetainfoDetectionMutation(\n  $input: ImportObjectDetectionsMutationInput!\n) {\n  importObjectDetections(input: $input) {\n    __typename\n    ... on MovieMetainfo {\n      id\n      movieClips {\n        id\n        swingImage {\n          id\n          signedUrl\n        }\n        objectDetections {\n          id\n          objectType\n          xmin\n          ymin\n          xmax\n          ymax\n        }\n      }\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5a6ae631ac2af38b37e51dcb41c61d46";

export default node;
