/**
 * @generated SignedSource<<128c8b28bda16c60d4a988746a4e44cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieInReviewRemandData$data = {
  readonly id: string;
  readonly " $fragmentType": "MovieInReviewRemandData";
};
export type MovieInReviewRemandData$key = {
  readonly " $data"?: MovieInReviewRemandData$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieInReviewRemandData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieInReviewRemandData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};

(node as any).hash = "460b4eb312c9f3e229019eb7084bfca3";

export default node;
